import { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
        margin: 0;
        font-size: 1.25em;
    }
    p {
        margin: 0;
    }
`;

interface Props {
    header: string | ReactElement;
    body: string;
}

export const Cell = ({ header, body }: Props) => {
    return (
        <Container>
            <h2>{header}</h2>
            <p>{body}</p>
        </Container>
    );
};
