import { ContractStatus } from 'types/enum/ContractStatus';

export const statusIdsForReinstate = [
    ContractStatus.Cancelled,
    ContractStatus.Expired,
    ContractStatus.Expired,
    ContractStatus.Lapsed,
    ContractStatus.Uncontactable,
    ContractStatus.FailedPayments,
];
export const statusIdsForCancel = [
    ContractStatus.Live,
    ContractStatus.Future,
    ContractStatus.WebsitePending,
    ContractStatus.SignatureRequired,
    ContractStatus.ExpiredDueToPaymentIssues,
    ContractStatus.Lapsed,
    ContractStatus.Uncontactable,
    ContractStatus.FailedPayments,
    ContractStatus.Pending,
];
export const statusIdsForChangeDates = [
    ContractStatus.Live,
    ContractStatus.Future,
    ContractStatus.WebsitePending,
    ContractStatus.SignatureRequired,
    ContractStatus.FailedPayments,
    ContractStatus.Pending,
];
export const statusIdsForActivate = [ContractStatus.Pending];

export const noEmailMatches = [
    /sc\+[a-zA-Z0-9]+@hometree\.co\.uk/, // SmartCover - sc+Acc123@hometree.co.uk
];
