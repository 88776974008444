import { DatabaseClaim } from 'features/claim/api/claim/type';
import { toCurrency } from 'utils/toCurrency';
import styled from 'styled-components';
import { XCircle } from 'assets/pandoraIcons';

const Container = styled.div`
    width: 300px;
    max-width: 300px;
    border-radius: 20px;
    box-shadow: 0px 7px 7px 1px #888888;
    padding: 15px;
    background-color: white;
    z-index: 1;
`;

const CostWrapper = styled.p`
    display flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    margin-top: 0
`;

const CloseButton = styled.button`
    background-color: inherit;
    border: none;
    width: 30px;
    cursor: pointer;
`;

const CostTitle = styled.span`
    color: ${({ theme }) => theme.colors['purple-medium']};
    font-style: normal;
    font-weight: 600;
`;

const CostSubtitle = styled.span`
    font-weight: 500;
    padding-right: 25px;
`;

const CostValue = styled.span`
    font-weight: 600;
`;

interface Props {
    claim: DatabaseClaim;
    setShowOtherCost: (show: boolean) => void;
}

export const CostBreakdown = ({ claim, setShowOtherCost }: Props) => {
    return (
        <Container>
            <CostWrapper>
                <CostTitle>TOTAL COST TO DATE</CostTitle>
                <CloseButton type="button" onClick={() => setShowOtherCost(false)}>
                    <XCircle />
                </CloseButton>
            </CostWrapper>
            <hr />
            <CostWrapper>
                <CostSubtitle>Total cost of claim</CostSubtitle>
                <CostValue data-testid="cost-total">
                    {toCurrency(claim.charge_total_cost || 0)}
                </CostValue>
            </CostWrapper>
            <hr />
            <CostWrapper>
                <CostSubtitle>Total cost of labour</CostSubtitle>
                <CostValue data-testid="labour-total">
                    {toCurrency(claim.labour_total || 0)}
                </CostValue>
            </CostWrapper>
            <hr />
            <CostWrapper>
                <CostSubtitle>Total cost of parts sourced and paid for by engineer</CostSubtitle>
                <CostValue data-testid="engineer-part-total">
                    £{(claim.engineer_parts_total || 0).toFixed(2)}
                </CostValue>
            </CostWrapper>
            <hr />
            <CostWrapper>
                <CostSubtitle>Total cost of parts sourced by us</CostSubtitle>
                <CostValue data-testid="hometree-part-total">
                    £{(claim.hometree_parts_total || 0).toFixed(2)}
                </CostValue>
            </CostWrapper>
        </Container>
    );
};
