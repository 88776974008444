import { PrimaryButton } from 'components/buttons';
import { useNavigate } from 'react-router-dom';

export const AddPricebookEntryButton = () => {
    const navigate = useNavigate();

    const addNewPricebookEntry = () => {
        navigate(`new`);
    };

    return (
        <PrimaryButton onClick={() => addNewPricebookEntry()}>
            Add package to pricebook
        </PrimaryButton>
    );
};
