import { ToastOptions } from 'react-hot-toast';

const toastConfig: ToastOptions = {
    style: {
        borderRadius: '10px',
        background: '#3f4955',
        color: '#fff',
    },
    position: 'top-right',
};

export default toastConfig;
