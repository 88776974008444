import { Button, Modal, Spinner } from '@HometreeEngineering/component-library';
import { ModalProps } from '@HometreeEngineering/component-library/dist/stories/Modal';
import { InfoIcon } from 'assets/pandoraIcons';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface WarningModalProps extends Omit<ModalProps, 'children'> {
    warningMessage: string | JSX.Element;
    onCancel: () => void;
    onSave: (event: any) => Promise<void>;
    isSubmitting?: boolean;
}

const adjustedStyle = {
    backgroundColor: '#FFF',
    maxWidth: '350px',
};

const WarningModal: React.FC<WarningModalProps> = ({
    isOpen,
    setIsOpen,
    warningMessage,
    onCancel,
    onSave,
    isSubmitting = false,
}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) setIsSubmitted(false);
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleOnClose={onCancel}
            customClass={styles.warningModal}
            style={adjustedStyle}
        >
            <div className={styles.warningContent}>
                <div className={styles.iconContainer}>
                    <InfoIcon />
                    <span className={styles.title}>Warning</span>
                    {isSubmitting || isSubmitted ? <Spinner /> : null}
                </div>
                <p>{warningMessage}</p>
                <div className={styles.buttonsContainer}>
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={onCancel}
                        isDisabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        isDisabled={isSubmitted}
                        onClick={(e) => {
                            setIsSubmitted(true);
                            onSave(e);
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default WarningModal;
