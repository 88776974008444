import { Modal, Spinner } from '@HometreeEngineering/component-library';
import { useCancelContractMutation } from 'api/contract/useCancelContractMutation';
import {
    useCancellationStatusQuery,
    useCancellationTypesQuery,
} from 'api/contract/useCancellationOptionsQuery';
import { useState } from 'react';
import toast from 'react-hot-toast';

import Dropdown from 'components/Dropdown';
import ModalButtons from '../ModalButtons';
import ModalHeader from './ModalHeader';
import styles from './index.module.scss';

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    customerFacingId: string;
    contractId: number;
    isGCCustomer: boolean;
    isCBCustomer: boolean;
}

const CancelContractModal = ({
    isOpen,
    setIsOpen,
    customerFacingId,
    contractId,
    isGCCustomer,
    isCBCustomer,
}: Props) => {
    const [description, setDescription] = useState<string>('');
    const [cancelGC, setCancelGC] = useState<boolean>(false);
    const [cancelCB, setCancelCB] = useState<boolean>(false);
    const [isCancellingContract, setIsCancellingContract] = useState<boolean>(false);
    const [isStatusEmpty, setIsStatusEmpty] = useState(false);
    const [isTypeEmpty, setIsTypeEmpty] = useState(false);
    const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ value: 'Please select', id: 0 });
    const [selectedTypeOption, setSelectedTypeOption] = useState({ value: 'Please select', id: 0 });

    const {
        isError: isCancellationTypesError,
        isLoading: isLoadingCancellationTypes,
        data: cancellationTypesData,
    } = useCancellationTypesQuery();

    const {
        isError: isCancellationStatusError,
        isLoading: isLoadingCancellationStatus,
        data: cancellationStatusData,
    } = useCancellationStatusQuery();
    const cancelContractMutation = useCancelContractMutation();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (selectedTypeOption.value === 'Please select') {
            setIsTypeEmpty(true);
            return;
        }
        if (selectedOption.value === 'Please select') {
            setIsStatusEmpty(true);
            return;
        }
        setIsCancellingContract(true);
        const data = {
            cancellation_status: selectedOption.id,
            cancellation_type: selectedTypeOption.id,
            cancellation_description: description,
            id: contractId,
            cancel_gocardless: cancelGC,
            cancel_chargebee: cancelCB,
        };
        try {
            await cancelContractMutation.mutateAsync(data);
            setIsCancellingContract(false);
            setIsOpen(false);
            toast.success('Contract cancelled successfully');
        } catch (error) {
            setIsCancellingContract(false);
            setIsOpen(false);
            toast.error((error as Error)?.message || 'Error cancelling contract');
        }
    };
    const isButtonsDisabled =
        selectedTypeOption.value === 'Please select' ||
        selectedOption.value === 'Please select' ||
        !description;

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            style={{ backgroundColor: 'white', width: '90%' }}
        >
            <div className={styles.container}>
                <ModalHeader customerFacingId={customerFacingId} />
                {isLoadingCancellationStatus || isLoadingCancellationTypes ? (
                    <Spinner />
                ) : (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={styles.selectWrapper}>
                            <label
                                className={`${styles.label} ${
                                    isStatusEmpty ? styles.labelError : ''
                                }`}
                                htmlFor="cancellationStatus"
                            >
                                Cancellation status
                                {!isCancellationStatusError &&
                                    !isLoadingCancellationStatus &&
                                    cancellationStatusData?.data?.length && (
                                        <>
                                            <div className={styles.cancelDropdown}>
                                                <Dropdown
                                                    testId="status-select"
                                                    customStyle={styles.customDropdown}
                                                    options={
                                                        cancellationStatusData.data.map(
                                                            (cancelStatus) => cancelStatus.name
                                                        ) || ['Please Select']
                                                    }
                                                    selectedOption={selectedOption.value}
                                                    setSelectedOption={(val: string) => {
                                                        const selected =
                                                            cancellationStatusData.data.find(
                                                                (status) => status.name === val
                                                            );
                                                        if (selected) {
                                                            setSelectedOption({
                                                                value: val,
                                                                id: selected?.id,
                                                            });
                                                            setIsStatusEmpty(false);
                                                        } else {
                                                            setIsStatusEmpty(true);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                            </label>
                            <label
                                className={`${styles.label} ${
                                    isTypeEmpty ? styles.labelError : ''
                                }`}
                                htmlFor="cancellationTypes"
                            >
                                Cancellation type
                                {!isCancellationTypesError &&
                                    !isLoadingCancellationTypes &&
                                    cancellationTypesData?.data?.length && (
                                        <>
                                            <div className={styles.cancelDropdown}>
                                                <Dropdown
                                                    testId="type-select"
                                                    customStyle={styles.customDropdown}
                                                    options={
                                                        cancellationTypesData.data
                                                            .sort((a, b) =>
                                                                a.name.localeCompare(b.name)
                                                            )
                                                            .map(
                                                                (cancelTypeStatus) =>
                                                                    cancelTypeStatus.name
                                                            ) || ['Please Select']
                                                    }
                                                    selectedOption={selectedTypeOption.value}
                                                    setSelectedOption={(val: any) => {
                                                        const selected =
                                                            cancellationTypesData?.data?.find(
                                                                (types) => types.name === val
                                                            );
                                                        if (selected) {
                                                            setSelectedTypeOption({
                                                                value: val,
                                                                id: selected.id,
                                                            });
                                                            setIsTypeEmpty(false);
                                                        } else {
                                                            setIsTypeEmpty(true);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                            </label>
                        </div>
                        <label
                            className={`${styles.label} ${
                                isDescriptionEmpty ? styles.labelError : ''
                            }`}
                            htmlFor="description"
                        >
                            Cancellation description
                            <textarea
                                name="description"
                                id="description"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                className={`${styles.textArea} ${
                                    isDescriptionEmpty ? styles.inputError : ''
                                }`}
                                onBlur={() => setIsDescriptionEmpty(!description)}
                                onInput={() => setIsDescriptionEmpty(false)}
                                data-testid="description-input"
                            />
                        </label>
                        {isDescriptionEmpty && (
                            <p className={styles.errorText}>Cancellation description is required</p>
                        )}
                        {isGCCustomer && (
                            <label
                                className={`${styles.label} ${styles.labelCancelGC} `}
                                htmlFor="cancelGC"
                            >
                                <input
                                    type="checkbox"
                                    name="cancelGC"
                                    id="cancelGC"
                                    onChange={(e) => setCancelGC(e.currentTarget.checked)}
                                    checked={cancelGC}
                                    data-testid="cancel-gc-input"
                                />
                                Cancel GoCardless mandate?
                            </label>
                        )}
                        {isCBCustomer && (
                            <label
                                className={`${styles.label} ${styles.labelCancelGC} `}
                                htmlFor="cancelCB"
                            >
                                <input
                                    type="checkbox"
                                    name="cancelCB"
                                    id="cancelGC"
                                    onChange={(e) => setCancelCB(e.currentTarget.checked)}
                                    checked={cancelCB}
                                    data-testid="cancel-cb-input"
                                />
                                Cancel chargebee subscription?
                            </label>
                        )}

                        <ModalButtons
                            handleSubmit={handleSubmit}
                            isSubmitting={isCancellingContract}
                            isSubmitButtonDisabled={isButtonsDisabled}
                        />
                    </form>
                )}
            </div>
        </Modal>
    );
};

export default CancelContractModal;
