import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { MutationFunction } from 'react-query';
import { PRICEBOOKS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type CreatePricebookEntryQueryKey = {
    pricebookId: number | undefined;
    packageId: number;
    contribution: number;
    price: number;
    minimumRenewalPrice: number | null;
};

interface CreatePricebookResponse {
    id: number;
    message: string;
}

export const createPricebookEntry: MutationFunction<
    CreatePricebookResponse,
    CreatePricebookEntryQueryKey
> = async (queryKey) => {
    const { pricebookId, packageId, contribution, price, minimumRenewalPrice } = queryKey;

    const token = await getAmplifyIdTokenFromCookie();

    const response = await fetch(
        `${API_BASE_URL}/${PRICEBOOKS_BASE_PATH}/model/pricebooks/entries/create`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                pricebook_id: pricebookId,
                package_id: packageId,
                contribution,
                price,
                minimum_renewal_price: minimumRenewalPrice,
            }),
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};
