import { getAuthenticatedUser } from 'hooks/useAuthenticatedUser';

export const getAmplifyIdTokenFromCookie = async () => {
    const data = await getAuthenticatedUser();
    const token =
        localStorage[
            `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_CLIENT_ID}.${data.username}.idToken`
        ];

    if (typeof token !== 'string') {
        return null;
    }

    return token;
};
