import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { MutationFunction } from 'react-query';
import { CLAIM_ACTIVITIES_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { CreateClaimActivityResponse } from './type';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

type CreateClaimActivityQueryKey = { claimId: number; summary: string; description: string };

export const createClaimActivity: MutationFunction<
    CreateClaimActivityResponse,
    CreateClaimActivityQueryKey
> = async (queryKey) => {
    const { claimId, summary, description } = queryKey;

    const token = await getAmplifyIdTokenFromCookie();

    const response = await fetch(
        `${API_BASE_URL}/${CLAIM_ACTIVITIES_BASE_PATH}/v1/claimActivity/${claimId}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                summary,
                description,
            }),
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};
