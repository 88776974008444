import { Params } from 'api/contract';
import { useContractQuery } from 'api/contract/useContractQuery';
import { MenuBar } from 'components/menu-bar';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getCookieDomain } from 'utils/getCookieDomain';
import { getJobsByClaimId } from '../api/jobs';
import { useClaimQuery } from '../hooks/useClaimQuery';
import { AccountDetails } from './AccountDetails';
import { Activity } from './Activity';
import { AlternativeAccommodation } from './AlternativeAccommodation';
import { ClaimDetails } from './ClaimDetails';
import { Header } from './Header';
import { Jobs } from './Jobs';

const Container = styled.main`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.gray_000};
`;

export const Claim = () => {
    const urlParams = useParams();
    const { data, isLoading, isError } = useClaimQuery();
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();
    const contractId = data?.contract_id || 0;
    const expandQuery = 'property,account';

    const params: Params = {
        expand: expandQuery,
    };
    const {
        isError: isContractError,
        isLoading: isLoadingContract,
        data: contractData,
    } = useContractQuery({ contractId, params });

    const {
        data: jobsData,
        isLoading: isLoadingJobs,
        isError: isJobsError,
    } = useQuery(['jobs', { claimId: urlParams.claimId || '' }], getJobsByClaimId, {
        enabled: !!urlParams.claimId,
    });

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    const customerDetails = {
        name: '',
        phoneNumber: '',
        address: '',
    };

    if (contractData) {
        customerDetails.name =
            `${contractData?.data[0].account.first_name} ${contractData?.data[0].account.last_name}` ||
            '';
        customerDetails.address = `${contractData?.data[0].property.address_line_1} ${
            contractData?.data[0].property.address_line_2
                ? `,${contractData?.data[0].property.address_line_2}`
                : ''
        } ${
            contractData?.data[0].property.address_line_3
                ? `,${contractData?.data[0].property.address_line_3}`
                : ''
        } ${contractData?.data[0].property.postcode}`;
        customerDetails.phoneNumber =
            contractData?.data[0].account.phone1 === null ||
            contractData?.data[0].account.phone1 === ''
                ? `+${contractData?.data[0].account.phone2_prefix}${contractData?.data[0].account.phone2}`
                : `+${contractData?.data[0].account.phone1_prefix}${contractData?.data[0].account.phone1}`;
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header jobs={jobsData} />
                <ClaimDetails data={data} isLoading={isLoading} isError={isError} />
                <Jobs
                    contractCustomerDetails={customerDetails}
                    claimStatus={data?.claim_status_name}
                    isLoadingContract={isLoadingContract}
                    isLoading={isLoadingJobs}
                    data={jobsData}
                    isError={isJobsError}
                />
                <AlternativeAccommodation
                    isAlternativeAccommodation={
                        data && data.alternative_accommodation
                            ? data.alternative_accommodation === 1
                            : false
                    }
                />
                {process.env.REACT_APP_FEATURE_TOGGLE_ACTIVITIES === 'true' ? <Activity /> : ''}
                <AccountDetails
                    data={contractData}
                    isLoading={isLoadingContract}
                    isError={isContractError}
                />
            </Container>
        </>
    );
};
