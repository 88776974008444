import { useState } from 'react';
import { Container, Label, MoreInfoButton, MoreInfo } from './style';

interface Props {
    isAlternativeAccommodation: boolean;
}

export const AlternativeAccommodation = ({ isAlternativeAccommodation }: Props) => {
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    return (
        <Container>
            <Label htmlFor="alternativeAccomodation">
                <input
                    type="checkbox"
                    id="alternativeAccomodation"
                    checked={isAlternativeAccommodation}
                />
                Alternative accommodation
            </Label>
            <MoreInfoButton
                onClick={() => setShowMoreInfo(!showMoreInfo)}
                highlight={showMoreInfo}
                data-testid="more-info-button"
            >
                ?
            </MoreInfoButton>
            {showMoreInfo && (
                <MoreInfo data-testid="more-info">
                    This customer is entitled to “Alternative Accommodations” if issue has not been
                    resolved within 72 hours, up to £120 in fees.
                </MoreInfo>
            )}
        </Container>
    );
};
