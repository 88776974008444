import { ClaimStatus } from 'types/enum/ClaimStatus';
import { useClaimQuery } from 'features/claim/hooks/useClaimQuery';
import { SecondaryButton } from 'components/buttons';

interface Props {
    toggleModal: () => void;
}

export const CloseClaimButton = ({ toggleModal }: Props) => {
    const { data, isLoading } = useClaimQuery();

    const isDisabled =
        isLoading ||
        data?.claim_status_id === ClaimStatus.CLOSED ||
        data?.claim_status_id !== ClaimStatus.HOMETREE_NOT_FIX;
    return (
        <SecondaryButton onClick={() => toggleModal()} disabled={isDisabled}>
            Close Claim
        </SecondaryButton>
    );
};
