import { useMutation, useQueryClient } from 'react-query';
import { createClaimActivity } from '../api/claimActivity/createClaimActivity';

export const useClaimActivityMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(createClaimActivity, {
        onSuccess: () => {
            queryClient.invalidateQueries('claimActivityNotes');
        },
    });
};
