import styled from 'styled-components';
import { useState } from 'react';

// MUI
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

import { AuthoriseInvoiceRequest, DatabaseJob } from 'features/claim/api/jobs/type';
import { authoriseInvoice } from 'features/claim/api/jobs/authoriseInvoice';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { Table } from '../Table';
import generateInvoiceTableColumns from './AuthoriseInvoiceTableColumns';

interface Props {
    open: boolean;
    closeModal: () => void;
    jobs: DatabaseJob[] | undefined;
}

const style: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    maxHeight: '80%',
    display: 'block',
    borderRadius: '20px',
    maxWidth: '1050px',
};

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
`;

export const ErrorText = styled.p`
    color: red;
`;

export const AuthoriseInvoiceModal = ({ open, closeModal, jobs }: Props) => {
    const [selectedJobs, setSelectedJobs] = useState<AuthoriseInvoiceRequest[]>([]);
    const [isSubmiting, setIsSubmitting] = useState(false);
    const [isSubmitingError, setIsSubmittingError] = useState(false);

    if (!jobs || jobs.length === 0) {
        return null;
    }

    const rows = jobs.map((item) => ({ key: item.id, ...item }));

    const handleSelectJob = (
        e: React.ChangeEvent<HTMLSelectElement>,
        jobId: string | undefined
    ) => {
        if (e.target.value && jobId) {
            const checkedJob: DatabaseJob | undefined = jobs.find((job) => job.id === +jobId);

            if (checkedJob) {
                setSelectedJobs((prevState) => {
                    const newState = [...prevState];
                    const removeSelectedJobIfInState = newState.filter(
                        (job) => job.jobName !== checkedJob.sk_job_name
                    );
                    if (checkedJob.sk_job_name) {
                        removeSelectedJobIfInState.push({
                            jobName: checkedJob.sk_job_name,
                            totalCost: checkedJob.charge_total_cost || 0,
                            isAuthorised: e.target.value,
                        });
                    }
                    return removeSelectedJobIfInState;
                });
            }
        }
    };

    const handleSubmit = async () => {
        if (selectedJobs && selectedJobs.length > 0) {
            setIsSubmitting(true);
            try {
                await authoriseInvoice(selectedJobs);

                setIsSubmitting(false);
                setIsSubmittingError(false);
                closeModal();
                window.location.reload();
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('Error authoring invoices in pandora react: ', err);
                setIsSubmittingError(true);
                setIsSubmitting(false);
            }
        }
    };

    const columns = generateInvoiceTableColumns(handleSelectJob, selectedJobs);

    return (
        <Modal data-testid="close-claim-modal" open={open} onClose={closeModal}>
            <Box sx={style}>
                <h2 data-testid="authorise-invoice-title">Authorise Invoices</h2>
                {jobs && jobs.length > 0 && (
                    <Table
                        rows={rows}
                        columns={columns}
                        hideFooter
                        defaultSort={{
                            column: 'sk_job_name',
                            direction: 'desc',
                        }}
                    />
                )}
                {isSubmitingError && !isSubmiting && (
                    <ErrorText>There was an error creating new job.</ErrorText>
                )}

                <ButtonWrapper>
                    <PrimaryButton
                        onClick={handleSubmit}
                        disabled={selectedJobs.length === 0}
                        loading={isSubmiting}
                    >
                        Authorise
                    </PrimaryButton>
                    <SecondaryButton onClick={() => closeModal()} disabled={isSubmiting}>
                        Cancel
                    </SecondaryButton>
                </ButtonWrapper>
            </Box>
        </Modal>
    );
};
