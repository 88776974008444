import { useMutation, useQueryClient } from 'react-query';
import { updateContract } from '../api/updateContract';

export const useUpdateContractMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(updateContract, {
        onSuccess: () => {
            // Invalidate and refetch any queries related to contracts
            queryClient.invalidateQueries('contract');
        },
    });
};
