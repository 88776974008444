import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// MUI
import { Box, Modal, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

// Components
import { useClaimMutation } from 'features/claim/hooks/useClaimMutation';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ClaimStatus } from 'types/enum/ClaimStatus';
import { StatusSelect } from './StatusSelect';
import { RejectionSelect } from './NotFixingSelect';
import { NoteBox } from './NoteBox';

interface Props {
    open: boolean;
    closeModal: () => void;
}

const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const InputContainer = styled.section`
    display: flex;
    gap: 1rem;
`;

interface Option {
    value: string;
    copy: string;
}

interface StatusReasons {
    [key: string]: Option[];
}

export const EditStatusModal = ({ open, closeModal }: Props) => {
    const urlParams = useParams();
    const { mutate } = useClaimMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [cause, setCause] = useState('');
    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setStatus(0);
    }, [open]);

    const statusToReason: Record<number, StatusReasons> = {
        [ClaimStatus.HOMETREE_NOT_FIX]: {
            causeList: [
                { value: 'rejection', copy: 'Rejection' },
                { value: 'BER', copy: 'BER' },
                { value: 'self fix', copy: 'Self Fix' },
                { value: 'intermittent fault', copy: 'Intermittent Fault' },
                { value: 'customer unreachable', copy: 'Customer Unreachable' },
                { value: 'other', copy: 'Other' },
            ],
            BER: [
                { value: 'BER - Cost', copy: 'Cost' },
                { value: 'BER - Obsolete parts', copy: 'Obsolete parts' },
                { value: 'BER - Sludge', copy: 'Sludge' },
                { value: 'BER - Scale', copy: 'Scale' },
                { value: 'BER - Corrosion/Rust', copy: 'Corrosion/Rust' },
                { value: 'BER - Gas Fire needs replacing', copy: 'Gas Fire needs replacing' },
                { value: 'BER - Appliance needs replacing', copy: 'Appliance needs replacing' },
            ],
            rejection: [
                { value: 'Rejection - Sludge', copy: 'Sludge' },
                { value: 'Rejection - Scale', copy: 'Scale' },
                { value: 'Rejection - No Access', copy: 'No Access' },
                { value: 'Rejection - Installation Fault', copy: 'Installation Fault' },
                { value: 'Rejection - Showers', copy: 'Showers' },
                { value: 'Rejection - Taps', copy: 'Taps' },
                { value: 'Rejection - Light bulbs', copy: 'Light bulbs' },
                { value: 'Rejection - Garden items', copy: 'Garden items' },
                { value: 'Rejection - Electrical appliances', copy: 'Electrical appliances' },
                { value: 'Rejection - Collapsed drains/roots', copy: 'Collapsed drains/roots' },
                { value: 'Rejection - Bathroom ceramics', copy: 'Bathroom ceramics' },
                { value: 'Rejection - Alternative boilers', copy: 'Alternative boilers' },
                { value: 'Rejection - Seals and grout', copy: 'Seals and grout' },
                { value: 'Rejection - Upgrade', copy: 'Upgrade' },
                { value: 'Rejection - Shared drains', copy: 'Shared drains' },
                { value: 'Rejection - Wifi or hub issues', copy: 'Wifi or hub issues' },
            ],
            'self fix': [
                {
                    value: 'Self Fix - Unfroze condensate pipes',
                    copy: 'Unfroze condensate pipes',
                },
                { value: 'Self Fix - Repressurised Boiler', copy: 'Repressurised Boiler' },
                { value: 'Self Fix - Reset boiler', copy: 'Reset boiler' },
                { value: 'Self Fix - Bleeded radiators', copy: 'Bleeded radiators' },
                {
                    value: 'Self Fix - Changed thermostat batteries',
                    copy: 'Changed thermostat batteries',
                },
            ],
            'intermittent fault': [
                { value: 'Intermittent Fault - Customer to monitor', copy: 'Customer to monitor' },
            ],
            other: [
                { value: 'Other - Miscategorised Claim', copy: 'Miscategorised Claim' },
                { value: 'Other - User Error', copy: 'User Error' },
                { value: 'Other - Repair no longer required', copy: 'Repair no longer required' },
            ],
            'customer unreachable': [
                {
                    value: 'Customer Unreachable - Customer Unreachable',
                    copy: 'Customer Unreachable',
                },
            ],
        },
    };

    const onSubmit = async () => {
        setIsLoading(true);

        mutate(
            {
                claimId: urlParams.claimId || '',
                body: {
                    claimStatusId: status,

                    ...(status === ClaimStatus.HOMETREE_NOT_FIX && {
                        reason,
                        note: {
                            description,
                            summary: `Claim moved to Hometree Not Fixing due to ${reason}`,
                        },
                    }),
                    // TODO remove hardcoded status in summary + don't send on every status change
                },
            },
            {
                onSuccess: () => {
                    closeModal();
                },
                onSettled: () => setIsLoading(false),
            }
        );
    };

    const doesStatusChangeRequireReason = !!statusToReason[status];

    const isSubmitDisabled =
        status <= 0 ||
        (doesStatusChangeRequireReason && (cause === '' || reason === '' || description === ''));

    return (
        <Modal data-testid="edit-claim-modal" open={open} onClose={closeModal}>
            <Box sx={style}>
                {/* {console.log('CLAIM STATUS NAME: ', ClaimStatus[status])} */}
                <Typography data-testid="close-claim-title" variant="h6" component="h2">
                    Edit Claim: {urlParams.claimId}
                </Typography>

                <StatusSelect value={status} onChange={setStatus} />
                {statusToReason[status] && (
                    <>
                        <Typography data-testid="close-claim-title" variant="h6" component="h2">
                            Additional Information:
                        </Typography>
                        <RejectionSelect
                            cause={cause}
                            reason={reason}
                            causes={statusToReason[status].causeList}
                            reasons={statusToReason[status][cause]}
                            onChangeCause={setCause}
                            onChangeReason={setReason}
                        />
                        <NoteBox onChangeNote={setDescription} value={description} />
                    </>
                )}

                <InputContainer>
                    <PrimaryButton
                        className="small"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isSubmitDisabled}
                    >
                        Submit
                    </PrimaryButton>
                    <SecondaryButton className="small" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </InputContainer>
            </Box>
        </Modal>
    );
};
