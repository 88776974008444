export enum ContractStatus {
    WebsitePending = 1,
    SignatureRequired = 2,
    Future = 3,
    Live = 4,
    Abandoned = 5,
    Expired = 6,
    AbandonedClosed = 7,
    Cancelled = 8,
    Renewed = 9,
    ExpiredDueToPaymentIssues = 10,
    Lapsed = 11,
    Uncontactable = 12,
    FailedPayments = 13,
    Pending = 14,
}
