import { useMutation, useQueryClient } from 'react-query';
import { createNote } from './createNote';

export const useCreateNoteMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(createNote, {
        onSuccess: () => {
            // Invalidate and refetch any queries related to contracts
            queryClient.invalidateQueries('contract');
        },
    });
};
