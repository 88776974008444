import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface ContractTypes {
    id: number;
    date_created: string;
    date_modified: string;
    active: number;
    author: string;
    name: string;
    last_modified_by: string | null;
}
export interface ContractTypesResponse {
    data: ContractTypes[];
}

export const contractTypesSchema: JSONSchemaType<ContractTypesResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    active: { type: 'number' },
                    author: { type: 'string' },
                    name: { type: 'string' },
                    last_modified_by: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetContractTypesResponse = PandoraErrorResponse | ContractTypesResponse;

export const getContractTypesResponseSchema: JSONSchemaType<GetContractTypesResponse> = {
    type: 'object',
    anyOf: [contractTypesSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

export enum ContractBillingTypeName {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Quarterly = 'Quarterly',
}
export interface ContractBillingType {
    id: number;
    date_created: string;
    date_modified: string;
    active: number;
    author: string;
    name: ContractBillingTypeName;
    last_modified_by: string | null;
}

export interface ContractBillingTypesResponse {
    data: ContractBillingType[];
}

export const contractBillingTypesSchema: JSONSchemaType<ContractBillingTypesResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    active: { type: 'number' },
                    author: { type: 'string' },
                    name: { type: 'string' },
                    last_modified_by: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetContractBillingTypesResponse = PandoraErrorResponse | ContractBillingTypesResponse;

export const getContractBillingTypesResponseSchema: JSONSchemaType<GetContractBillingTypesResponse> =
    {
        type: 'object',
        anyOf: [contractBillingTypesSchema, pandoraErrorResponseSchema],
        additionalProperties: true,
    };

export interface Status {
    id: number;
    date_created: string;
    date_modified: string;
    active: number;
    author: string;
    name: string;
    last_modified_by: string;
}

export interface ContractStatusesResponse {
    data: Status[];
}

export const contractStatusesSchema: JSONSchemaType<ContractStatusesResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    active: { type: 'number' },
                    author: { type: 'string' },
                    name: { type: 'string' },
                    last_modified_by: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetContractStatusesResponse = PandoraErrorResponse | ContractStatusesResponse;

export const getContractStatusesResponseSchema: JSONSchemaType<GetContractStatusesResponse> = {
    type: 'object',
    anyOf: [contractStatusesSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

export interface PromocodeType {
    id: number;
    date_created: string;
    date_modified: string;
    author: string;
    last_modified_by: string | null;
    active: number;
    name: string;
}

export interface PromocodeTypeResponse {
    data: PromocodeType[];
}

export const promocodeTypeSchema: JSONSchemaType<PromocodeTypeResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    active: { type: 'number' },
                    author: { type: 'string' },
                    name: { type: 'string' },
                    last_modified_by: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetPromocodeTypeResponse = PandoraErrorResponse | PromocodeTypeResponse;

export const getPromocodeTypeResponseSchema: JSONSchemaType<GetPromocodeTypeResponse> = {
    type: 'object',
    anyOf: [promocodeTypeSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

interface ContractThirdParty {
    thirdPartyId: string;
    thirdPartyType: string;
    dateCreated: string;
}

export interface ContractThirdPartiesResponse {
    relations: ContractThirdParty[];
}

export const contractThirdPartiesSchema: JSONSchemaType<ContractThirdPartiesResponse> = {
    type: 'object',
    properties: {
        relations: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    thirdPartyId: { type: 'string' },
                    thirdPartyType: { type: 'string' },
                    dateCreated: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetContractThirdPartiesResponse = PandoraErrorResponse | ContractThirdPartiesResponse;

export const getContractThirdPartiesResponseSchema: JSONSchemaType<GetContractThirdPartiesResponse> =
    {
        type: 'object',
        anyOf: [contractThirdPartiesSchema, pandoraErrorResponseSchema],
        additionalProperties: true,
    };
