export enum ContractBillingType {
    Monthly = 1,
    Yearly = 2,
    Quarterly = 3,
}

export enum RenewalStatus {
    pending = 1,
    cancelled = 2,
    renewed = 3,
    draft = 4,
    'pending - new mandate required' = 5,
    'pending - tried renewing, mandate failed' = 6,
    'pending - missing payment' = 7,
    'pending - insurer approval' = 8,
}
