import { Table } from '@HometreeEngineering/component-library';
import { Alert, AlertTitle } from '@mui/material';
import { Contract } from 'api/contract/types';
import { useContractQuery } from 'api/contract/useContractQuery';
import { usePackagesQuery } from 'api/packages/usePackagesQuery';
import { Header } from 'components/NewHeader';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { MenuBar } from 'components/menu-bar';
import { useContractBillingTypesQuery } from 'features/contract/hooks/useContractBillingTypesQuery';
import { useContractTypesQuery } from 'features/contract/hooks/useContractTypesQuery';
import { buildContractsTableData } from 'features/contract/utils/tableDataBuilder';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCookieDomain } from 'utils/getCookieDomain';
import styles from './index.module.scss';

const Contracts = () => {
    const [details, setDetails] = useState<Contract[] | undefined>(undefined);
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();
    const {
        isError: isContractError,
        isLoading: isLoadingContract,
        data: contractData,
    } = useContractQuery({});
    const { data: billingTypesData } = useContractBillingTypesQuery();
    const { data: typesData } = useContractTypesQuery();
    const { data: packagesData } = usePackagesQuery();

    useEffect(() => {
        if (contractData && contractData.data) {
            setDetails(contractData.data);
        }
    }, [contractData]);

    const headers = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'type', value: 'Type' },
            { key: 'status', value: 'Status' },
            { key: 'contract_number', value: 'Contract Number' },
            { key: 'start_date', value: 'Start Date' },
            { key: 'end_date', value: 'End Date' },
            { key: 'linked_account', value: 'Linked Account' },
            { key: 'linked_property', value: 'Linked Property' },
            { key: 'package', value: 'Package' },
            { key: 'billing_type', value: 'Billing Type' },
            { key: 'contribution', value: 'Call-Out Fee' },
            { key: 'billing_amount', value: 'Current Payment Amount' },
            { key: 'modified_date', value: 'Modified Date' },
            { key: 'author', value: 'Author' },
        ],
        []
    );
    const tableData = useMemo(
        () =>
            buildContractsTableData(
                contractData?.data,
                billingTypesData,
                typesData?.data,
                packagesData?.data
            ),
        [contractData?.data, billingTypesData, typesData?.data, packagesData?.data]
    );

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }
    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    if (isContractError) {
        return (
            <>
                <MenuBar />
                <div className={styles.pageContainer}>
                    <div className={styles.container}>
                        <Alert severity="error">
                            <AlertTitle>Contracts Error</AlertTitle>
                            Unable to load contracts
                        </Alert>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <MenuBar />
            <div className={styles.pageContainer}>
                {isLoadingContract || !details ? (
                    <SkeletonLoader repeat={6} />
                ) : (
                    <>
                        <Header text="Contracts" />{' '}
                        <div className={styles.contractsContainer}>
                            <Table
                                headers={headers}
                                data={tableData || []}
                                customStyle={styles.differentHeader}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default Contracts;
