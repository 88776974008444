import { cancelContract } from 'features/contract/api/cancellation';
import { useMutation, useQueryClient } from 'react-query';

export const useCancelContractMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(cancelContract, {
        onSuccess: () => {
            // Invalidate and refetch any queries related to contracts
            queryClient.invalidateQueries('contract');
        },
    });
};
