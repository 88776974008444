import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse } from 'api/authentication/types';
import { Promocode } from 'api/contract/types';

export enum SearchType {
    PROMOCODE = 'promocode',
}

export interface SearchResponseItem {
    entryType: SearchType.PROMOCODE;
    entry: Promocode;
    substringMatch: number;
    overallScore: number;
}

export interface SearchResponse {
    data: SearchResponseItem[];
}

export const searchPromocodeSchema: JSONSchemaType<SearchResponseItem[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            entryType: {
                type: 'string',
                enum: [SearchType.PROMOCODE],
            },
            entry: {
                type: 'object',
                properties: {
                    id: { type: 'number', nullable: true },
                    date_created: { type: 'string', nullable: true },
                    date_modified: { type: 'string', nullable: true },
                    active: { type: 'number', nullable: true },
                    code: { type: 'string', nullable: true },
                    type: { type: 'number', nullable: true },
                    pricebook_id: { type: 'number', nullable: true },
                    expiry_date: { type: 'string', nullable: true },
                    description: { type: 'string', nullable: true },
                    max_uses: { type: 'number', nullable: true },
                    uses: { type: 'number', nullable: true },
                    author: { type: 'string', nullable: true },
                    last_modified_by: { type: 'string', nullable: true },
                    family: { type: 'number', nullable: true },
                    value: { type: 'number', nullable: true },
                },
                required: [],
            },
            substringMatch: { type: 'number' },
            overallScore: { type: 'number' },
        },
        required: ['entryType', 'entry'],
    },
};

export type SearchPromocodeResponse = PandoraErrorResponse | SearchResponse;
