import { useLayoutEffect } from 'react';

const useAutoSizeTextArea = (
    id: string,
    textAreaRef: HTMLTextAreaElement | null,
    value: string
) => {
    useLayoutEffect(() => {
        const textArea = textAreaRef ?? document.getElementById(id);

        if (textArea) {
            textArea.style.height = '0px';
            const { scrollHeight } = textArea;
            if (scrollHeight > 40) {
                textArea.style.height = `${scrollHeight - 10}px`;
            } else {
                textArea.style.height = '19px';
            }
        }
    }, [textAreaRef, id, value]);
};

export default useAutoSizeTextArea;
