import { useState } from 'react';

// MUI
import { Box, Modal, InputLabel } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { DatabaseJob } from 'features/claim/api/jobs/type';
import { createJob } from 'features/claim/api/jobs/createJob';
import { XCircle } from 'assets/pandoraIcons';

import {
    style,
    ButtonContainer,
    InformationFieldWrapper,
    FieldMainTitle,
    FieldTitle,
    TextArea,
    ErrorText,
    Input,
    FieldWrapper,
    Title,
    Paragraph,
    CloseButton,
    TitleWrapper,
    InputSelect,
    TitleSelectText,
} from './style';
import { BoilerDetailsFields } from './BoilerDetailsFields';

interface Props {
    open: boolean;
    closeModal: () => void;
    job: DatabaseJob;
    contractCustomerDetails: {
        name: string;
        phoneNumber: string;
        address: string;
    };
}

const getManufacturer = (boilerMake: string) => {
    if (boilerMake.toLowerCase() === 'vaillant') {
        return 'Vaillant';
    }
    if (boilerMake.toLowerCase() === 'worcester bosch') {
        return 'Worcester Bosch';
    }
    if (boilerMake.toLowerCase() === 'ideal') {
        return 'Ideal';
    }
    if (boilerMake.toLowerCase() === 'baxi') {
        return 'Baxi';
    }
    return '3rd Party';
};

export const CreateNewJobModal = ({ open, closeModal, job, contractCustomerDetails }: Props) => {
    const [manufacturer, setManufacturer] = useState(getManufacturer(job.sk_boiler_make || ''));
    const [faultDescription, setFaultDescription] = useState(job.summary || '');
    const [boilerDetails, setBoilerDetails] = useState({
        make: job.sk_boiler_make || '',
        model: job.sk_boiler_model || '',
        age: job.sk_boiler_age || '',
        gcNumber: job.sk_boiler_gc_number || '',
    });
    const [phoneNumber, setPhoneNumber] = useState(contractCustomerDetails.phoneNumber || '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [manufacturerError, setManufacturerError] = useState(false);
    const [newJobName, setNewJobName] = useState('');

    const handleSubmit = async () => {
        setIsSubmitting(true);
        if (manufacturer === '3rd Party') {
            setManufacturerError(true);
            setIsSubmitting(false);
            setErrorSubmitting(false);
            setNewJobName('');
            return;
        }
        try {
            const data = {
                boilerDetails,
                faultDescription,
                manufacturer,
                jobId: job.sk_job_id,
                customerPhoneNumber: phoneNumber,
            };

            const response = await createJob(data);

            setIsSubmitting(false);
            setErrorSubmitting(false);
            setNewJobName(response.jobName);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error creating new job in pandora react: ', err);
            setErrorSubmitting(true);
            setIsSubmitting(false);
            setNewJobName('');
        }
    };

    return (
        <Modal data-testid="create-job-modal" open={open} onClose={closeModal}>
            <Box sx={{ ...style, height: newJobName ? 'auto' : '90%' }}>
                <TitleWrapper>
                    <Title data-testid="title">Send to 3rd Party</Title>
                    <CloseButton
                        type="button"
                        onClick={() => {
                            closeModal();
                            window.location.reload();
                        }}
                    >
                        <XCircle />
                    </CloseButton>
                </TitleWrapper>
                {!newJobName ? (
                    <>
                        <FieldWrapper>
                            <InputLabel id="manufacturer">
                                <TitleSelectText>Select:</TitleSelectText>
                            </InputLabel>
                            <InputSelect
                                id="manufacturer-InputSelect"
                                onChange={(e) => setManufacturer(e.target.value)}
                                value={manufacturer}
                                data-testid="manaufacturer-select"
                            >
                                <option value="3rd Party" data-testid="manufacturer">
                                    3rd Party
                                </option>
                                <option value="Vaillant" data-testid="manufacturer">
                                    Vaillant
                                </option>
                                <option value="Worcester Bosch" data-testid="manufacturer">
                                    Worcester Bosch
                                </option>
                                <option value="Ideal" data-testid="manufacturer">
                                    Ideal
                                </option>
                                <option value="Baxi" data-testid="manufacturer">
                                    Baxi
                                </option>
                            </InputSelect>
                        </FieldWrapper>
                        <FieldMainTitle>Boiler Details</FieldMainTitle>
                        <InformationFieldWrapper>
                            <BoilerDetailsFields
                                setBoilerDetails={setBoilerDetails}
                                boilerDetails={boilerDetails}
                            />
                        </InformationFieldWrapper>
                        <FieldMainTitle>Customer Details</FieldMainTitle>
                        <InformationFieldWrapper>
                            <FieldWrapper data-testid="customer-name">
                                <FieldTitle>Name: </FieldTitle>
                                <Paragraph>{contractCustomerDetails.name}</Paragraph>
                            </FieldWrapper>
                            <FieldWrapper data-testid="customer-phone-number">
                                <FieldTitle>Phone Number:</FieldTitle>
                                <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber || ''}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    data-testid="input"
                                />
                            </FieldWrapper>
                            <FieldWrapper data-testid="customer-address">
                                <FieldTitle>Address: </FieldTitle>
                                <Paragraph>{contractCustomerDetails.address}</Paragraph>
                            </FieldWrapper>
                        </InformationFieldWrapper>
                        <FieldMainTitle>Fault Details</FieldMainTitle>
                        <InformationFieldWrapper>
                            <FieldWrapper>
                                <FieldTitle>Description: </FieldTitle>
                                <TextArea
                                    id="faultDescription"
                                    name="faultDescription"
                                    value={faultDescription || ''}
                                    onChange={(e) => setFaultDescription(e.target.value)}
                                    data-testid="input"
                                />
                            </FieldWrapper>
                        </InformationFieldWrapper>
                        {errorSubmitting && !isSubmitting && !manufacturerError && (
                            <ErrorText>There was an error creating new job.</ErrorText>
                        )}
                        {manufacturerError && !isSubmitting && !errorSubmitting && (
                            <ErrorText>Please select a valid manufacturer</ErrorText>
                        )}
                        <ButtonContainer>
                            <PrimaryButton
                                onClick={handleSubmit}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                data-testid="send-button"
                            >
                                Send
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={closeModal}
                                disabled={isSubmitting}
                                data-testid="cancel-button"
                            >
                                Cancel
                            </SecondaryButton>
                        </ButtonContainer>
                    </>
                ) : (
                    <FieldTitle>
                        <span>New job has been created with job name {newJobName}</span>
                    </FieldTitle>
                )}
            </Box>
        </Modal>
    );
};
