import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPricebook } from '../api/pricebook';

interface Props {
    refetch?: boolean;
}

export const usePricebookQuery = ({ refetch = true }: Props) => {
    const urlParams = useParams();
    return useQuery(['pricebook', { id: urlParams.pricebookId || '' }], getPricebook, {
        enabled: refetch,
    });
};
