import styled from 'styled-components';

import { SxProps } from '@mui/system';

export const style: SxProps = {
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow:
        '0px 11px 15px 0px #888888, 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    p: 4,
    position: 'absolute',
    top: '51%',
    left: '50%',
    overflow: 'scroll',
    height: '80%',
    display: 'block',
    borderRadius: '20px',
};

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors['purple-medium']};
    font-style: normal;
    font-weight: 600;
    margin-top: 0;
`;

export const TitleSelectText = styled.p`
    font-weight: 600;
    color: black;
    margin: 0;
    font-size: 1em;
`;

export const CloseButton = styled.button`
    background-color: inherit;
    border: none;
    width: 30px;
    cursor: pointer;
    align-self: baseline;
`;

export const InputSelect = styled.select`
    width: 18.5em;
    height: 2em;
    border: 1px solid #d8dbdd;
    font-size: 1.1em;
    padding: 0 1em;
    border: 0;
    outline: 1px solid #d8dbdd;
    border-right: 16px solid transparent;
`;

export const InputContainer = styled.section`
    display: flex;
    gap: 1rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
        margin-right: 20px;
    }
`;

export const InformationFieldWrapper = styled.div`
    border: 1px solid black;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 20px;
`;

export const FieldMainTitle = styled.p`
    font-weight: bolder;
    font-size: 1.1em;
`;

export const FieldTitle = styled.p`
    color: black;
    margin: 0;
    font-size: 1em;
    margin-bottom: 20px;
`;

export const Input = styled.input`
    padding: 0 1em;
    font-size: 1em;
    line-height: 2em;
    border: 1px solid #d8dbdd;
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 19em;
`;

export const TextArea = styled.textarea`
    border: 1px solid #d8dbdd;
    min-height: 100px;
    resize: none;
    width: 22em;
    padding: 0 1em;
`;

export const ErrorText = styled.p`
    color: red;
    font-size: 1em;
`;

export const FieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Paragraph = styled.p`
    width: 19em;
    margin: 0;
    margin-bottom: 20px;
    font-size: 1em;
`;
