import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'amplify';
import { PrimaryButton } from 'components/buttons';
import { MenuBar } from 'components/menu-bar';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCookieDomain } from 'utils/getCookieDomain';

const Container = styled.div`
    background: rgb(23, 23, 28);
    width: 100%;
    height: 100vh;
    z-index: 0;
`;

const Background = styled.div`
    background-image: url('/homepagetree.jpg');
    background-repeat: none;
    background-position: center;
    background-size: cover;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    min-height: 100vh;
    z-index: -1;
`;

const LoggedOutWrapper = styled.div`
    text-align: center;
    margin: 0 auto;
`;

const Title = styled.h1`
    font-family: 'Coaster', 'Courier New', Courier, monospace;
    font-size: 10em;
    font-weight: 100;
    color: white;
`;

const LoginPrompt = () => {
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();
    const [isLoggingOut] = useState(Cookies.get('isLoggingOut'));

    useEffect(() => {
        if (isLoggingOut) {
            const allLocalStorage = Object.keys({ ...localStorage });
            allLocalStorage.forEach((localStorageItem) => {
                if (localStorageItem.startsWith('CognitoIdentityServiceProvider')) {
                    localStorage.removeItem(localStorageItem);
                }
            });

            const domain = getCookieDomain(process.env.REACT_APP_STAGE);
            Cookies.remove('last-visited', { domain });
            Cookies.remove('amplify_id_token', { domain });
            Cookies.remove('amplify_access_token', { domain });
            Cookies.remove('isLoggingOut', { domain });
        }
    }, [isLoggingOut]);

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && userData.isAuthenticated) {
        const lastVisited = Cookies.get('last-visited');
        if (lastVisited) {
            window.location.assign(lastVisited);
        }
        return (
            <>
                <Container>
                    <Background />
                    <MenuBar />
                </Container>
            </>
        );
    }

    return (
        <>
            <Container>
                <Background />
                <LoggedOutWrapper>
                    <Title>Pandora</Title>
                    <PrimaryButton
                        type="button"
                        onClick={() =>
                            Auth.federatedSignIn({
                                provider: CognitoHostedUIIdentityProvider.Google,
                            })
                        }
                    >
                        Log in
                    </PrimaryButton>
                </LoggedOutWrapper>
            </Container>
        </>
    );
};

export default LoginPrompt;
