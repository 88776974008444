import { useState, useCallback } from 'react';

type ReturnType = [boolean, () => void];

export const useToggle = (initialValue = false): ReturnType => {
    const [value, setValue] = useState(initialValue);
    const toggle = useCallback(() => {
        setValue((v) => !v);
    }, []);
    return [value, toggle];
};
