import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { QueryFunction } from 'react-query';

import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { CONTRACTS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import axios from 'axios';
import { ContractThirdPartiesResponse, getContractThirdPartiesResponseSchema } from './types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type GetContractThirdPartiesQueryKey = [string, number];

export const getContractThirdParties: QueryFunction<
    ContractThirdPartiesResponse,
    GetContractThirdPartiesQueryKey
> = async ({ queryKey }) => {
    const token = await getAmplifyIdTokenFromCookie();

    const [, contractId] = queryKey;

    const response = await axios(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/${contractId}/third_parties`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const { data } = response;

    const ajv = new Ajv({ coerceTypes: true });
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getContractThirdPartiesResponseSchema);
    const valid = validate(data);

    if (!valid) {
        throw new Error(
            `Failed to validate getContractThirdParties response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
