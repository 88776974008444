import { Account } from 'api/contract/types';

const accountTableData = (data: Account) => {
    const accountDetails = data;
    const number =
        accountDetails.phone1 === null || accountDetails.phone1 === ''
            ? `+${accountDetails.phone2_prefix}${accountDetails.phone2}`
            : `+${accountDetails.phone1_prefix}${accountDetails.phone1}`;

    const formattedAccountDetails = {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phone1: number,
    };
    return formattedAccountDetails;
};
export default accountTableData;
