import { Contract } from 'api/contract/types';
import ClaimsTable from '../ClaimsTable';
import PMSClaimsTable from '../PMSClaimsTable';
import RenewalsTable from '../RenewalsTable';
import styles from './index.module.scss';

const TablesDisclaimer = ({ relations }: { relations: Contract['relations'] }) => {
    return (
        <>
            {relations?.claimdb_claims?.length ||
            relations?.renewals?.length ||
            relations?.claimdb_claims?.length ? (
                <div className={styles.page}>
                    {relations?.renewals?.length ? (
                        <div className={styles.container}>
                            <RenewalsTable renewals={relations.renewals} />
                        </div>
                    ) : null}
                    {relations?.claimdb_claims?.length ? (
                        <div className={styles.container}>
                            <ClaimsTable claims={relations?.claimdb_claims} />
                        </div>
                    ) : null}
                    {relations?.claims?.length ? (
                        <div className={styles.container}>
                            <PMSClaimsTable pmsClaims={relations.claims} />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};
export default TablesDisclaimer;
