import { ThirdParty, ThirdPartyIdType } from 'types/enum/ThirdParty';

export const getThirdPartyIdType = (value: string) => {
    if (value === ThirdPartyIdType.SmartCover) {
        return ThirdParty.SmartCover;
    }

    if (value === ThirdPartyIdType.Openrent) {
        return ThirdParty.Openrent;
    }

    return null;
};
