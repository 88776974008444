import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { JOBS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { CreateJobBody } from './type';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const createJob = async (data: CreateJobBody) => {
    const token = await getAmplifyIdTokenFromCookie();
    const response = await fetch(`${API_BASE_URL}/${JOBS_BASE_PATH}/v1/jobs`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};
