import { FormControl, InputLabel } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface StatusInfo {
    value: string;
    copy: string;
}

interface Props {
    cause?: string;
    reason?: string;
    causes?: Array<StatusInfo>;
    reasons?: Array<StatusInfo>;
    onChangeCause: React.Dispatch<React.SetStateAction<string>>;
    onChangeReason: React.Dispatch<React.SetStateAction<string>>;
}

export const RejectionSelect = ({
    cause,
    reason,
    causes,
    reasons,
    onChangeCause,
    onChangeReason,
}: Props) => {
    return (
        <>
            <FormControl sx={{ marginTop: 3, marginBottom: 3 }}>
                <InputLabel id="rejection-cause">Cause</InputLabel>
                <MuiSelect
                    labelId="rejection-cause"
                    id="rejection-cause-select"
                    label="Rejection Cause"
                    onChange={(e) => onChangeCause(e.target.value)}
                    defaultValue=""
                    value={cause || ''}
                >
                    <MenuItem value="">
                        <em>Please select</em>
                    </MenuItem>
                    {causes?.map((causeOption) => {
                        return (
                            <MenuItem value={causeOption.value} key={Math.random()}>
                                {causeOption.copy}
                            </MenuItem>
                        );
                    })}
                </MuiSelect>
            </FormControl>
            <FormControl sx={{ marginBottom: 3 }}>
                <InputLabel id="rejection-reason">Reason</InputLabel>
                <MuiSelect
                    labelId="rejection-reason"
                    id="rejection-reason-select"
                    label="Rejection Reason"
                    onChange={(e) => onChangeReason(e.target.value)}
                    defaultValue=""
                    value={reason || ''}
                >
                    {reasons?.map((reasonOption) => {
                        return (
                            <MenuItem value={reasonOption.value} key={Math.random()}>
                                {reasonOption.copy}
                            </MenuItem>
                        );
                    })}
                </MuiSelect>
            </FormControl>
        </>
    );
};
