import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import axios from 'axios';

import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { CONTRACTS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import {
    CancelContractRequestPayload,
    CancellationOptionsResponse,
    getCancellationOptionsResponseSchema,
} from './cancellation.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const getCancellationTypes = async (): Promise<CancellationOptionsResponse> => {
    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/contracts/cancellationtypes`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.data;

    const ajv = new Ajv({ coerceTypes: true });
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getCancellationOptionsResponseSchema);
    const valid = validate(data);

    if (!valid) {
        throw new Error(
            `Failed to validate get cancellation type response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from get cancellation type api: ${data.message}`);
    }

    return data;
};

export const getCancellationStatus = async (): Promise<CancellationOptionsResponse> => {
    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/contracts/cancellationstatus`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.data;

    const ajv = new Ajv({ coerceTypes: true });
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getCancellationOptionsResponseSchema);
    const valid = validate(data);

    if (!valid) {
        throw new Error(
            `Failed to validate get cancellation status response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from get cancellation status api: ${data.message}`);
    }

    return data;
};

export const cancelContract = async (payload: CancelContractRequestPayload) => {
    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios.post(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/contracts/cancel`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    if (response.data.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (response.data.status === ' error') {
        throw new Error('Network response was not ok');
    }

    return response.data;
};
