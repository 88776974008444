import { PrimaryButton } from 'components/buttons';
import { CallOutFee } from '../types';

interface Props {
    setEditingCallOutFee: React.Dispatch<React.SetStateAction<CallOutFee | null>>;
    toggleModal: () => void;
}

export const AddButton = ({ toggleModal, setEditingCallOutFee }: Props) => {
    return (
        <PrimaryButton
            onClick={() => {
                setEditingCallOutFee(null);
                toggleModal();
            }}
        >
            Add call out fee
        </PrimaryButton>
    );
};
