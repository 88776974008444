import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClaimActivityNotes } from '../api/claimActivity/getClaimActivityNotes';

export const useGetClaimActivityNotes = () => {
    const urlParams = useParams();
    const { claimId } = urlParams;
    const { data, isLoading, isError } = useQuery(
        ['claimActivityNotes', { id: claimId! }],
        getClaimActivityNotes,
        {
            enabled: !!claimId,
        }
    );

    const activityNotes =
        (data &&
            data?.activities.sort((a, b) => {
                const c = Date.parse(b.dateCreated);
                const d = Date.parse(a.dateCreated);
                return c - d;
            })) ||
        [];

    return { activityNotes, isLoading, isError };
};
