import { format, utcToZonedTime } from 'date-fns-tz';

export const formatLondonTime = (date: Date | string | number): string => {
    try {
        const timeZone = 'Europe/London';
        const londonTime = utcToZonedTime(date, timeZone);
        const formattedDate = format(londonTime, 'dd/MM/yyyy H:mm', { timeZone });

        return formattedDate;
    } catch (e) {
        // eslint-disable-next-line
        console.error('Error getting datetime for', date);
        return 'INVALID DATE';
    }
};
