import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface ClaimActivityResponse {
    id: number;
    dateCreated: string;
    dateModified: string;
    createdBy: string;
    lastModifiedBy: string;
    fkClaim: {
        id: number;
        dateCreated: string;
    } | null;
    fkStatus: {
        id: number;
        name: string;
        code: string;
        dateCreated: string;
        dateModified: string;
    } | null;
}

export interface NoteResponse {
    id: number;
    dateCreated: string;
    dateModified: string;
    createdBy: string;
    lastModifiedBy: string;
    fkClaimActivity: {
        id: number;
    } | null;
    summary: string;
    description: string;
}

export interface CreateClaimActivityBody {
    summary: string;
    description: string;
}

export interface CreateClaimActivityResponse {
    claimActivity: ClaimActivityResponse;
    note: NoteResponse;
}

export interface Activity {
    id: number;
    dateCreated: string;
    dateModified: string;
    createdBy: string;
    lastModifiedBy: string;
    claimId: number;
    statusId: number;
    notes: ActivityNote[];
}

export interface ActivityNote {
    id: number;
    createdBy: string;
    summary?: string | null;
    description?: string | null;
    activityType?: string | null;
}

export interface ActivityNotes {
    activities: Activity[];
}

export const databaseNoteSchema: JSONSchemaType<ActivityNote> = {
    type: 'object',
    properties: {
        id: { type: 'integer' },
        createdBy: { type: 'string' },
        summary: { type: 'string', nullable: true },
        description: { type: 'string', nullable: true },
        activityType: { type: 'string', nullable: true },
    },
    required: ['id', 'createdBy'],
};

export const databaseActivityNoteSchema: JSONSchemaType<Activity> = {
    type: 'object',
    properties: {
        id: { type: 'integer' },
        dateCreated: { type: 'string' },
        dateModified: { type: 'string' },
        createdBy: { type: 'string' },
        lastModifiedBy: { type: 'string' },
        claimId: { type: 'number' },
        statusId: { type: 'number' },
        notes: { type: 'array', items: databaseNoteSchema },
    },
    required: ['id', 'dateCreated', 'dateModified'],
};

export const databaseActivityNotesSchema: JSONSchemaType<ActivityNotes> = {
    type: 'object',
    properties: {
        activities: {
            type: 'array',
            items: databaseActivityNoteSchema,
        },
    },
    required: ['activities'],
};

export type GetActivityNotesResponse = PandoraErrorResponse | ActivityNotes;

export const getActivityResponseSchema: JSONSchemaType<GetActivityNotesResponse> = {
    type: 'object',
    anyOf: [databaseActivityNotesSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
