import { Box, Modal, SxProps, Typography, TextField, InputAdornment } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CallOutFee } from '../types';

interface Props {
    open: boolean;
    closeModal: () => void;
    callOutFeeToEdit: CallOutFee | null;
    callOutFees: CallOutFee[];
    setCallOutFees: React.Dispatch<React.SetStateAction<CallOutFee[]>>;
}

const boxStyle: SxProps = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
    left: '50%',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
};

const TextInputMui = styled.label`
    display: flex;
    align-items: center;
    color: #3f4955;
    font-weight: 600;
`;

const ButtonContainer = styled.div`
    display: 'flex';

    button {
        margin-right: 20px;
    }
`;

export const AddOrEditCallOutFeeModal = ({
    open,
    closeModal,
    callOutFeeToEdit,
    callOutFees,
    setCallOutFees,
}: Props) => {
    const [callOutFee, setCallOutFee] = useState<string | undefined>(undefined);
    const [price, setPrice] = useState<string | undefined>(undefined);
    const [minimumRenewalPrice, setMinimumRenewalPrice] = useState<string | undefined>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (callOutFeeToEdit) {
            setCallOutFee(
                String(callOutFeeToEdit?.callOutFee === 0 ? 0 : callOutFeeToEdit?.callOutFee || '')
            );
            setPrice(String(callOutFeeToEdit?.price === 0 ? 0 : callOutFeeToEdit?.price || ''));
            setMinimumRenewalPrice(
                String(
                    callOutFeeToEdit?.minimumRenewalPrice === 0
                        ? 0
                        : callOutFeeToEdit?.minimumRenewalPrice || ''
                )
            );
        }
    }, [callOutFeeToEdit]);

    const cleanForm = () => {
        setCallOutFee('');
        setPrice('');
        setMinimumRenewalPrice('');
    };

    const buildNewCallOutFee = (): CallOutFee => {
        const maxId =
            callOutFees.length > 0 ? Math.max(...callOutFees.map((value) => value.id)) : 0;
        return {
            id: maxId + 1,
            callOutFee: Number(callOutFee),
            price: Number(price),
            minimumRenewalPrice: minimumRenewalPrice ? Number(minimumRenewalPrice) : null,
        };
    };

    const buildEditCallOutFee = (): CallOutFee => {
        return {
            id: callOutFeeToEdit?.id || 0,
            callOutFee: Number(callOutFee),
            price: Number(price),
            minimumRenewalPrice: minimumRenewalPrice ? Number(minimumRenewalPrice) : null,
        };
    };

    const addCallOutFee = (value: CallOutFee) => {
        setCallOutFees([...callOutFees, value]);
    };

    const editCallOutFee = (newValue: CallOutFee) => {
        const updatedCallOutFees = callOutFees.map((value) => {
            if (value.id === callOutFeeToEdit?.id) {
                return newValue;
            }

            return value;
        });

        setCallOutFees(updatedCallOutFees);
    };

    const checkValueAlreadySet = (callOutFeeValue: string | undefined): boolean => {
        return !!callOutFees.find((value) => value.callOutFee === Number(callOutFeeValue));
    };

    const callOutFeeHelperText = (callOutFeeValue: string | undefined): string => {
        if (callOutFeeValue === '' || callOutFeeValue === undefined) {
            return 'Please enter a call out fee: it must be an integer greater or equal to 0';
        }

        if (checkValueAlreadySet(callOutFeeValue) && !callOutFeeToEdit) {
            return 'This call out fee is already set';
        }

        return '';
    };

    const onSubmit = async () => {
        setIsLoading(true);
        if (callOutFeeToEdit) {
            editCallOutFee({
                ...buildEditCallOutFee(),
                added: callOutFeeToEdit.added,
                edited: true,
            });
        } else {
            addCallOutFee({ ...buildNewCallOutFee(), added: true });
        }
        cleanForm();
        closeModal();
        setIsLoading(false);
    };

    const onChangeFn = (
        e: React.ChangeEvent<HTMLInputElement>,
        setFn: React.Dispatch<React.SetStateAction<string | undefined>>,
        type: string
    ) => {
        const { value } = e.target;
        const FLOATCURRENCYREGEX = /^(\d+(\.\d{0,2})?|\.\d{1,2})$/;

        switch (type) {
            case 'integer-currency':
                if (value && !Number.isNaN(Number(value)) && Number(value) >= 0) {
                    setFn(String(Number(value)));
                }
                break;

            case 'float-currency':
                if (value && FLOATCURRENCYREGEX.test(value)) {
                    setFn(value);
                }
                break;

            default:
                break;
        }

        if (value === '' || value === undefined) {
            setFn('');
        }
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={boxStyle}>
                <Typography variant="h6" component="h2">
                    Add call out fee
                </Typography>

                <TextInputMui htmlFor="calloutfee">
                    <TextField
                        id="calloutfee"
                        label="Call out fee"
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        value={callOutFee || ''}
                        error={
                            callOutFee === '' ||
                            (checkValueAlreadySet(callOutFee) && !callOutFeeToEdit)
                        }
                        helperText={callOutFeeHelperText(callOutFee)}
                        disabled={!!callOutFeeToEdit}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeFn(e, setCallOutFee, 'integer-currency')
                        }
                        required
                    />
                </TextInputMui>

                <TextInputMui htmlFor="price">
                    <TextField
                        id="price"
                        label="Package price"
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        value={price || ''}
                        error={price === ''}
                        helperText={
                            price === '' || price === undefined
                                ? 'Please enter a package price'
                                : ''
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeFn(e, setPrice, 'float-currency')
                        }
                        required
                    />
                </TextInputMui>

                <TextInputMui htmlFor="minimumrenewalprice">
                    <TextField
                        id="minimumrenewalprice"
                        label="Minimum renewal price"
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        value={minimumRenewalPrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeFn(e, setMinimumRenewalPrice, 'float-currency')
                        }
                    />
                </TextInputMui>

                <ButtonContainer>
                    <PrimaryButton
                        className="small"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={
                            !callOutFee ||
                            !price ||
                            (checkValueAlreadySet(callOutFee) && !callOutFeeToEdit)
                        }
                    >
                        Add
                    </PrimaryButton>
                    <SecondaryButton className="small" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};
