import { formatPrice } from 'utils/formatPrice';
import { ContractBillingType } from '../types/ContractBillingType';

export const getPaymentAmountForBillingType = (
    paymentAmount: number,
    billingType: ContractBillingType
) => {
    switch (billingType) {
        case ContractBillingType.Monthly:
            return formatPrice(paymentAmount);
        case ContractBillingType.Quarterly:
            return formatPrice(paymentAmount * 3);
        case ContractBillingType.Yearly:
            return formatPrice(paymentAmount * 12);
        default:
            return formatPrice(paymentAmount);
    }
};

export const getYearlyPriceForBillingType = (
    paymentAmount: number,
    billingType: ContractBillingType
) => {
    switch (billingType) {
        case ContractBillingType.Monthly:
            return formatPrice(paymentAmount * 12);
        case ContractBillingType.Quarterly:
            return formatPrice(paymentAmount * 3);
        case ContractBillingType.Yearly:
            return formatPrice(paymentAmount);
        default:
            return formatPrice(paymentAmount);
    }
};
