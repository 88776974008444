import styled from 'styled-components';
import { useParams } from 'react-router';
import { useState } from 'react';

// MUI
import { Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

// Components
import { ClaimStatus } from 'types/enum/ClaimStatus';
import { useClaimMutation } from 'features/claim/hooks/useClaimMutation';
import { PrimaryButton, SecondaryButton } from 'components/buttons';

interface Props {
    open: boolean;
    closeModal: () => void;
}
const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const InputContainer = styled.section`
    display: flex;
    gap: 1rem;
`;

const StyledText = styled.p`
    margin-top: 3rem;
    margin-bottom: 3rem;
`;
export const CloseClaimModal = ({ open, closeModal }: Props) => {
    const urlParams = useParams();
    const { mutate } = useClaimMutation();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        // fix this empty default
        setIsLoading(true);
        mutate(
            {
                claimId: urlParams.claimId || '',
                body: { claimStatusId: ClaimStatus.CLOSED },
            },
            {
                onSuccess: () => closeModal(),
                onSettled: () => setIsLoading(false),
            }
        );
    };

    return (
        <Modal data-testid="close-claim-modal" open={open} onClose={closeModal}>
            <Box sx={style}>
                <Typography data-testid="close-claim-title" variant="h6" component="h2">
                    Close Claim: {urlParams.claimId}
                </Typography>

                <StyledText>
                    Are you sure you want to close this claim? You will not be able to reopen it.
                </StyledText>

                <InputContainer>
                    <PrimaryButton className="small" onClick={onSubmit} loading={isLoading}>
                        Submit
                    </PrimaryButton>
                    <SecondaryButton className="small" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </InputContainer>
            </Box>
        </Modal>
    );
};
