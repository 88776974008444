import { Button, Table } from '@HometreeEngineering/component-library';
import { useCreateNoteMutation } from 'api/account/useCreateNoteMutation';
import { Note } from 'api/contract/types';
import { EditIcon, PlusIcon } from 'assets/pandoraIcons';
import { buildNotesTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import WarningModal from '../../ContractModals/WarningModal';
import styles from '../../index.module.scss';
import notesStyles from './index.module.scss';

type NotesProps = {
    notes: Note[];
    accountId: number | undefined;
    propertyId: number | undefined;
    contractId: number | undefined;
};

const Notes = ({ notes, accountId, propertyId, contractId }: NotesProps) => {
    const [newNote, setNewNote] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const createNoteMutation = useCreateNoteMutation();
    const headers = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'note_id', value: 'Note ID' },
            { key: 'note', value: 'Note' },
            { key: 'created_date', value: 'Created Date' },
            { key: 'modified_date', value: 'Modified Date' },
            { key: 'author', value: 'Author' },
        ],
        []
    );
    const handleSubmit = async () => {
        if (accountId && propertyId && contractId && !!newNote) {
            const data = {
                note: newNote,
                account_id: accountId,
                property_id: propertyId,
                contract_id: contractId,
            };

            try {
                setIsLoading(true);
                await createNoteMutation.mutateAsync(data);
                setIsLoading(false);
                toast.success('Note added successfully');
            } catch (error) {
                toast.error((error as Error)?.message || 'Error creating note');
            } finally {
                setIsLoading(false);
                setModalOpen(false);
                setIsAddNoteOpen(false);
            }
        }
    };

    const onCancel = () => {
        setNewNote('');
    };

    const data = useMemo(() => buildNotesTableData(notes), [notes]);

    return (
        <div className={`${styles.container} ${notesStyles.notesContainer}`}>
            <div className={notesStyles.notesHeader}>
                {!data ? (
                    <p className={notesStyles.notFound}>No notes yet.</p>
                ) : (
                    <p>Contract notes</p>
                )}
                <div className={notesStyles.addNewNote}>
                    <p>Add note</p>
                    <button
                        className={notesStyles.plusBtn}
                        onClick={() => setIsAddNoteOpen(true)}
                        type="button"
                        aria-label="open add note"
                    >
                        <PlusIcon />
                    </button>
                </div>
            </div>
            {isAddNoteOpen ? (
                <div className={notesStyles.noteInputContainer}>
                    <label htmlFor="description" className={notesStyles.noteInputContainer}>
                        <span className={notesStyles.label}>
                            New Note <EditIcon className={notesStyles.icon} />
                        </span>
                        <textarea
                            className={notesStyles.noteInput}
                            id="description"
                            name="description"
                            value={newNote}
                            onChange={(e) => setNewNote(e.target.value as string)}
                        />
                    </label>
                    <div className={notesStyles.submitButtons}>
                        <Button
                            onClick={() => {
                                onCancel();
                                setIsAddNoteOpen(false);
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => setModalOpen(true)} isDisabled={!newNote}>
                            Confirm
                        </Button>
                    </div>
                    {isModalOpen ? (
                        <WarningModal
                            isOpen={isModalOpen}
                            setIsOpen={setModalOpen}
                            warningMessage={`You are about to add a new note to this customer's account.
                            Please cancel if this was made in error.`}
                            onCancel={() => {
                                setModalOpen(false);
                            }}
                            onSave={handleSubmit}
                            isSubmitting={isLoading}
                        />
                    ) : null}
                </div>
            ) : null}
            {data ? (
                <Table headers={headers} data={data} customStyle={styles.differentHeader} />
            ) : null}
        </div>
    );
};

export default Notes;
