import { Alert, AlertTitle, Button } from '@mui/material';
import styled from 'styled-components';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { DatabasePricebooks } from 'features/pricebook/api/type';
import { LinkCell } from 'components/LinkCell';
import { formatDate } from 'utils/formatDate';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { SecondaryButton } from 'components/buttons';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { AddPricebookEntryButton } from './AddPricebookEntryButton';
import { Table } from '../Table';

const Container = styled.section`
    padding: 2rem 3rem;
`;

const Details = styled.section`
    background-color: ${({ theme }) => theme.colors.gray_100};
    display: flex;
    padding: 2rem 3rem;
    margin: 2rem 0;
    justify-content: space-between;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    justify-content: space-between;
    h2 {
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
    }
    p {
        margin: 0 1rem 0 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`;

const Separator = styled.div`
    display: blocked;
    height: 1rem;
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: DatabasePricebooks | undefined;
}

export const PricebookDetails = ({ data, isLoading, isError }: Props) => {
    const [redirectTo, setRedirectTo] = useState('');
    if (isLoading) {
        return (
            <Container>
                <SkeletonLoader repeat={2} />
            </Container>
        );
    }

    if (!data || isError) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Pricebook Details Error</AlertTitle>
                    Unable to load pricebook details
                </Alert>
            </Container>
        );
    }

    const pricebookData = data.data[0];
    const pricebookIds = [] as number[];
    const pricebookEntries = pricebookData.relations?.pricebook_entries
        ?.filter((entry) => {
            return entry.package_id;
        })
        ?.filter((entry) => {
            return entry.active === 1;
        })
        .filter((entry) => {
            const shouldInclude = !pricebookIds.includes(entry.package_id) as boolean;
            pricebookIds.push(entry.package_id);

            return shouldInclude;
        })
        .sort((a, b) => a.package_id - b.package_id);

    const columns = [
        {
            headerName: 'Package name',
            field: 'package_name',
            flex: 1,
        },
        {
            headerName: 'Package type',
            field: 'is_insurance',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <p>{params.row.is_insurance === 1 ? 'Insurance' : 'Maintenance'}</p>
            ),
        },
        {
            headerName: '',
            field: 'edit',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button
                    type="button"
                    onClick={() => {
                        setRedirectTo(
                            `/pricebooks/${pricebookData.id}/${params.row.package_id}/edit`
                        );
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    if (redirectTo) {
        return <Navigate to={redirectTo} />;
    }

    return (
        <>
            <Container>
                <Header>
                    <h2>Pricebook Details</h2>
                    <ButtonContainer>
                        <SecondaryButton disabled>Edit</SecondaryButton>
                    </ButtonContainer>
                </Header>
                <Details>
                    <LinkCell header="Name" body={pricebookData.name} />
                    <LinkCell header="Id" body={pricebookData.id.toString()} />
                    <LinkCell header="Date created" body={formatDate(pricebookData.date_created)} />
                    <LinkCell
                        header="Date modified"
                        body={formatDate(pricebookData.date_modified)}
                    />
                </Details>
                <Header>
                    <h2>Packages</h2>
                    <ButtonContainer>
                        <AddPricebookEntryButton />
                    </ButtonContainer>
                </Header>
                <Separator />
                <Table columns={columns} rows={[...(pricebookEntries || [])]} hideFooter />
            </Container>
        </>
    );
};
