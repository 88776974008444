import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface JobResponse {
    data: Data;
}

interface Data {
    items: JobItem[];
}

interface JobItem {
    id: number;
    dateCreated: string;
    dateModified: string;
    accountsNominalCode: string;
    additionalCookerHobCheck: null;
    additionalFireChecks: null;
    additionalLandlordApplianceCheck: null;
    additionalQuotedWork: null;
    asvBreakdownCombined: null;
    author: string;
    chargeBand: string;
    chargeFinal: number;
    chargeFixedFeeAbortcall: number;
    chargeFixedFeeAbortcallVat: number;
    chargeFixedFeeAsv: number;
    chargeFixedFeeAsvBreakdown: number;
    chargeFixedFeeIsv: number;
    chargeFixedFeeLandCheck: number;
    chargeHandling: number;
    chargeLabour: number;
    chargeLabourVat: number;
    chargeMaterial: number;
    chargeTotal: number;
    chargeVat: number;
    claimAsvIsvValidated: boolean;
    claimStatus: string;
    contributionFeeCollected: string;
    customerContribution: number;
    customerFacingId: string;
    dateInvoice: null;
    ePartsVat: number;
    hoursCharged: string;
    htClaim: string;
    htRepairVisit: string;
    htServiceVisit: string;
    isInsurance: boolean;
    jobType: string;
    lastModifiedBy: string;
    lastModifiedByName: string;
    moneyCollectedContribution: number;
    payingCompanyName: string;
    payingCompanyVatNum: string;
    pmsTrade: string;
    pmsid: null;
    provider: string;
    quoteIncome: number;
    skAbortReason: null;
    skAbortiveNormHrsNetRate: null;
    skAbortiveOutHrsNetRate: null;
    skActualEnd: null;
    skActualStart: null;
    skAdditionalHoursAuthorised: string;
    skCreateSbi: null;
    skCreatedDate: string;
    skEPartsDescription: null;
    skEPartsNetAmount: null;
    skElectricianNormHrsAddtlHrsNetRate: null;
    skElectricianNormHrsFirstHrNetRate: null;
    skElectricianOutHrsAddtlHrsNetRate: null;
    skElectricianOutHrsFirstHrNetRate: null;
    skEstimatedEnd: string;
    skEstimatedStart: string;
    skGasNormHrsAddtlHrsNetRate: null;
    skGasNormHrsFirstHrNetRate: null;
    skGasOutHrsAddtlHrsNetRate: null;
    skGasOutHrsFirstHrNetRate: null;
    skGralTradeNormHrsAddtlHrsNetRate: null;
    skGralTradeNormHrsFirstHrNetRate: null;
    skGralTradeOutHrsAddtlHrsNetRate: null;
    skGralTradeOutHrsFirstHrNetRate: null;
    skJobId: string;
    skJobName: string;
    skJobStatus: string;
    skLabourAdjustmentDescription: null;
    skLabourAdjustmentNetAmount: null;
    skLabourToBePaid: string;
    skLastModifiedDate: string;
    skNormalHours: boolean;
    skPlumberNormHrsAddtlHrsNetRate: null;
    skPlumberNormHrsFirstHrNetRate: null;
    skPlumberOutHrsAddtlHrsNetRate: null;
    skPlumberOutHrsFirstHrNetRate: null;
    skRegionId: string;
    skRegionName: string;
    skRelatedJob: string;
    skResourceAddress: null;
    skResourceCompanyName: null;
    skResourceId: null;
    skResourceName: null;
    skResourceVatForCompany: null;
    skResourceVatNum: null;
    skRoofer2manNormHrsAddtlHrsNetRate: null;
    skRoofer2manNormHrsFirstHrNetRate: null;
    skRoofer2manOutHrsAddtlHrsNetRate: null;
    skRoofer2manOutHrsFirstHrNetRate: null;
    skShippingCity: string;
    skShippingPostalCode: string;
    skShippingState: string;
    skShippingStreet: string;
    skStripeAmount: string;
    skStripeId: string;
    skW1BranchAddress: string;
    skW1CollectionDate: string;
    skW1CustomerReference: string;
    skW1OnlineOrderNumber: string;
    skW1OrderDescription: string;
    skW1OrderGrossAmount: string;
    skW1OrderNetAmount: string;
    skW1OrderVatAmount: string;
    skW2BranchAddress: string;
    skW2CollectionDate: null;
    skW2CustomerReference: string;
    skW2OnlineOrderNumber: string;
    skW2OrderDescription: string;
    skW2OrderGrossAmount: string;
    skW2OrderNetAmount: string;
    skW2OrderVatAmount: string;
    summary: string;
    supplementM25: null;
    supplementM25Holiday: null;
    skCovidSelfIsolate: string;
    skVulnerableCustomer: string;
    claim: number;
    skResolvedProblem: null;
    skUrgency: string;
    skComplaintFlag: boolean;
    skComplaintUrl: null;
}

export interface DatabaseJob {
    id: number;
    sk_job_name?: string | null;
    sk_job_status?: string | null;
    summary?: string | null;
    sk_urgency?: string | null;
    sk_visit_type?: string | null;
    sk_created_date?: string | null;
    sk_actual_start?: string | null;
    sk_resource_name?: string | null;
    sk_resource_id?: string | null;
    sk_job_id: string;
    sk_boiler_make?: string | null;
    sk_boiler_model?: string | null;
    sk_boiler_age?: string | null;
    sk_boiler_gc_number?: string | null;
    sk_w1_order_net_amount?: string | null;
    charge_total_cost?: number | null;
    invoice_authorised?: boolean | null;
    invoiced: boolean;
}

export const databaseJobSchema: JSONSchemaType<DatabaseJob> = {
    type: 'object',
    properties: {
        id: { type: 'integer' },
        sk_job_name: { type: 'string', nullable: true },
        sk_job_status: { type: 'string', nullable: true },
        summary: { type: 'string', nullable: true },
        sk_urgency: { type: 'string', nullable: true },
        sk_visit_type: { type: 'string', nullable: true },
        sk_created_date: { type: 'string', format: 'date-time', nullable: true },
        sk_actual_start: { type: 'string', format: 'date-time', nullable: true },
        sk_resource_name: { type: 'string', nullable: true },
        sk_resource_id: { type: 'string', nullable: true },
        sk_job_id: { type: 'string' },
        sk_boiler_make: { type: 'string', nullable: true },
        sk_boiler_model: { type: 'string', nullable: true },
        sk_boiler_age: { type: 'string', nullable: true },
        sk_boiler_gc_number: { type: 'string', nullable: true },
        sk_w1_order_net_amount: { type: 'string', nullable: true },
        charge_total_cost: { type: 'number', nullable: true },
        invoice_authorised: { type: 'boolean', nullable: true },
        invoiced: { type: 'boolean' },
    },
    required: ['id', 'sk_job_id'],
};

export interface DatabaseJobs {
    jobs: DatabaseJob[];
}

export type GetJobsResponse = PandoraErrorResponse | DatabaseJobs;

export const databaseJobsSchema: JSONSchemaType<DatabaseJobs> = {
    type: 'object',
    properties: {
        jobs: { type: 'array', items: databaseJobSchema },
    },
    required: ['jobs'],
};

export const getJobsResponseSchema: JSONSchemaType<GetJobsResponse> = {
    type: 'object',
    anyOf: [databaseJobsSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

export interface BoilerDetails {
    make: string;
    age: string;
    gcNumber: string;
    model: string;
}

export interface CreateJobBody {
    jobId: string;
    manufacturer: string;
    boilerDetails: BoilerDetails;
    faultDescription: string;
    customerPhoneNumber: string;
}

export interface AuthoriseInvoiceRequest {
    jobName: string | null;
    totalCost: number;
    isAuthorised: string;
}
