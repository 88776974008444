import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import axios from 'axios';
import { ACCOUNTS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type CreateNoteInput = {
    account_id: number;
    contract_id: number;
    note: string;
    property_id: number;
};

export type UpdateContractOutput = { success: boolean };

export const createNote = async (payload: CreateNoteInput) => {
    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios.post(
        `${API_BASE_URL}/${ACCOUNTS_BASE_PATH}/accounts/notes/create`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    if (response.data.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (response.data.status === 'error') {
        throw new Error('Could not create note');
    }
    return response.data;
};
