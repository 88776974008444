import { Alert, AlertTitle } from '@mui/material';
import { ContractResponse } from 'api/contract/types';
import { AccountIcon } from 'assets/pandoraIcons';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import styled from 'styled-components';
import { Table } from '../Table';
import accountTableData from './accountTableData';

const Container = styled.section`
    padding: 2rem 3rem;
`;

const IconContainer = styled.figure`
    margin: 0;
    width: 2rem;
    height: 2rem;
    svg {
        width: 2rem;
        height: 2rem;
        stroke: purple;
        fill: transparent;
    }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    h2 {
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
    }
    p {
        margin: 0 1rem 0 0;
    }
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: ContractResponse | undefined;
}

export const AccountDetails = ({ data, isLoading, isError }: Props) => {
    if (isLoading) {
        return (
            <Container>
                <SkeletonLoader repeat={3} />
            </Container>
        );
    }

    if (!data || isError) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Account Details Error</AlertTitle>
                    Unable to load account information for id something
                </Alert>
            </Container>
        );
    }

    const account = data?.data[0].account;
    const accountData = accountTableData(account);

    const columns = Object.entries({
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone1: 'Phone number',
    }).map(([key, value]) => ({
        headerName: value,
        field: key,
        width: key === 'email' ? 400 : 150,
    }));

    return (
        <Container>
            <Header>
                <h2>LINKED ACCOUNT</h2> <p>{account.customer_facing_id}</p>
                <IconContainer>
                    <AccountIcon />
                </IconContainer>
            </Header>
            <Table columns={columns} rows={[{ key: accountData.id, ...accountData }]} hideFooter />
        </Container>
    );
};
