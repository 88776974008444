import { BoilerDetails } from 'features/claim/api/jobs/type';

import { FieldTitle, FieldWrapper, Input } from './style';

interface Props {
    setBoilerDetails: (cb: (value: BoilerDetails) => BoilerDetails) => void;
    boilerDetails: BoilerDetails;
}

export const BoilerDetailsFields = ({ setBoilerDetails, boilerDetails }: Props) => {
    return (
        <>
            <FieldWrapper>
                <FieldTitle>Make: </FieldTitle>
                <Input
                    name="make"
                    value={boilerDetails.make || ''}
                    onChange={(e) =>
                        setBoilerDetails((prevState) => ({
                            ...prevState,
                            make: e.target.value,
                        }))
                    }
                    data-testid="input"
                />
            </FieldWrapper>
            <FieldWrapper>
                <FieldTitle>Age: </FieldTitle>
                <Input
                    name="age"
                    value={boilerDetails.age || ''}
                    onChange={(e) =>
                        setBoilerDetails((prevState) => ({
                            ...prevState,
                            age: e.target.value,
                        }))
                    }
                    data-testid="input"
                />
            </FieldWrapper>
            <FieldWrapper>
                <FieldTitle>Model: </FieldTitle>
                <Input
                    name="model"
                    value={boilerDetails.model || ''}
                    onChange={(e) =>
                        setBoilerDetails((prevState) => ({
                            ...prevState,
                            model: e.target.value,
                        }))
                    }
                    data-testid="input"
                />
            </FieldWrapper>
            <FieldWrapper>
                <FieldTitle>GC Number: </FieldTitle>
                <Input
                    name="gcNumber"
                    value={boilerDetails.gcNumber || ''}
                    onChange={(e) =>
                        setBoilerDetails((prevState) => ({
                            ...prevState,
                            gcNumber: e.target.value,
                        }))
                    }
                    data-testid="input"
                />
            </FieldWrapper>
        </>
    );
};
