import { Alert, AlertTitle, Button, MenuItem, TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { usePackagesQuery } from 'api/packages/usePackagesQuery';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { createPricebookEntry } from 'features/pricebook/api/pricebookEntry';
import { detelePricebookEntry } from 'features/pricebook/api/pricebookEntry/deleteEntry';
import { updatePricebookEntry } from 'features/pricebook/api/pricebookEntry/updateEntry';
import { DatabasePricebooks } from 'features/pricebook/api/type';
import { useToggle } from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AddOrEditCallOutFeeModal } from '../AddCallOutFeeModal';
import { Table } from '../Table';
import { CallOutFee } from '../types';
import { AddButton } from './AddButton';
import { SaveButton } from './SaveButton';

const Container = styled.section`
    padding: 2rem 3rem;
`;

const FormContainer = styled.section``;

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    h2 {
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
    }
    p {
        margin: 0 1rem 0 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
`;

const TextInputMui = styled.label`
    display: flex;
    align-items: center;
    color: #3f4955;
    font-weight: 600;
    margin-bottom: 1rem;
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    pricebookData: DatabasePricebooks | undefined;
    callOutFeesEntries?: CallOutFee[];
}

export const NewPricebookEntryForm = ({
    pricebookData,
    callOutFeesEntries,
    isLoading,
    isError,
}: Props) => {
    const urlParams = useParams();
    const [isAddCallOutFeeModalOpen, toggleAddCallOutFeeModalOpen] = useToggle(false);
    const [packageId, setPackage] = useState(urlParams.packageId || '');
    const [callOutFees, setCallOutFees] = useState<CallOutFee[]>(callOutFeesEntries || []);
    const [editingCallOutFee, setEditingCallOutFee] = useState<CallOutFee | null>(null);
    const [redirectTo, setRedirectTo] = useState('');

    useEffect(() => {
        setCallOutFees(callOutFeesEntries || []);
    }, [callOutFeesEntries]);

    const {
        data: packagesData,
        isLoading: packagesIsLoading,
        isError: packagesIsError,
    } = usePackagesQuery();

    const pricebookName = pricebookData?.data[0].name || '';
    const packageIds = [] as number[];

    pricebookData?.data[0].relations?.pricebook_entries
        ?.filter((entry) => {
            return entry.contribution && entry.price && entry.active === 1;
        })
        .forEach((entry) => {
            const notIncluded = !packageIds.includes(entry.package_id) as boolean;
            if (notIncluded) {
                packageIds.push(entry.package_id);
            }
        });

    const removeCallOutFee = (id: number) => () => {
        setCallOutFees(
            callOutFees.map((value) => {
                if (value.id === id) {
                    return {
                        ...value,
                        deleted: true,
                    };
                }

                return value;
            })
        );
    };

    const renderEditCallOutFeeModal = (id: number) => () => {
        const callOutFee = callOutFees.find((value) => value.id === id);
        setEditingCallOutFee(callOutFee || null);
        toggleAddCallOutFeeModalOpen();
    };

    const shouldNotAllowSelectedPackage = (id: string): boolean => {
        return !urlParams.packageId && packageIds.includes(Number(id));
    };

    const renderPackageNameHelperText = (selectedPackageId: string): string => {
        if (selectedPackageId === '') {
            return 'Please select a package';
        }

        if (shouldNotAllowSelectedPackage(packageId)) {
            return 'This pricebook already has an entry for this package';
        }

        return '';
    };

    const savePricebookEntries = async () => {
        if (urlParams.packageId) {
            const entriesToDelete = callOutFees.filter((value) => value.deleted && !value.added);
            const entriesToAdd = callOutFees.filter((value) => !value.deleted && value.added);
            const entriesToUpdate = callOutFees.filter(
                (value) => !value.deleted && !value.added && value.edited
            );

            const generateInsertQueries = entriesToAdd
                .map((callOutFeeValue) => {
                    return {
                        pricebookId: Number(urlParams.pricebookId),
                        packageId: Number(packageId),
                        contribution: callOutFeeValue.callOutFee,
                        price: callOutFeeValue.price * 100,
                        minimumRenewalPrice: callOutFeeValue.minimumRenewalPrice
                            ? callOutFeeValue.minimumRenewalPrice * 100
                            : null,
                    };
                })
                .map((queryObj) => {
                    return createPricebookEntry(queryObj);
                });

            const generateDeleteQueries = entriesToDelete
                .map((callOutFeeValue) => {
                    return {
                        pricebookEntryId: Number(callOutFeeValue.id),
                    };
                })
                .map((queryObj) => {
                    return detelePricebookEntry(queryObj);
                });

            const generateUpdateQueries = entriesToUpdate
                .map((callOutFeeValue) => {
                    return {
                        pricebookEntryId: Number(callOutFeeValue.id),
                        price: callOutFeeValue.price * 100,
                        minimumRenewalPrice: callOutFeeValue.minimumRenewalPrice
                            ? callOutFeeValue.minimumRenewalPrice * 100
                            : null,
                    };
                })
                .map((queryObj) => {
                    return updatePricebookEntry(queryObj);
                });

            await Promise.all(generateInsertQueries);
            await Promise.all(generateDeleteQueries);
            await Promise.all(generateUpdateQueries);
            setRedirectTo(`/pricebooks/${urlParams.pricebookId}`);
            return;
        }

        const generateInsertQueries = callOutFees
            .map((callOutFeeValue) => {
                return {
                    pricebookId: Number(urlParams.pricebookId),
                    packageId: Number(packageId),
                    contribution: callOutFeeValue.callOutFee,
                    price: callOutFeeValue.price * 100,
                    minimumRenewalPrice: callOutFeeValue.minimumRenewalPrice
                        ? callOutFeeValue.minimumRenewalPrice * 100
                        : null,
                };
            })
            .map((queryObj) => {
                return createPricebookEntry(queryObj);
            });

        await Promise.all(generateInsertQueries);
        setRedirectTo(`/pricebooks/${urlParams.pricebookId}`);
    };

    const columns = [
        {
            headerName: 'Call-out fee',
            field: 'callOutFee',
            width: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <p>£ {params.row.callOutFee.toFixed(2)}</p>
            ),
        },
        {
            headerName: 'Package price monthly',
            field: 'price',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <p>£ {params.row.price.toFixed(2)}</p>
            ),
        },
        {
            headerName: 'Minimum renewal price',
            field: 'minimum_renewal_price',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                if (params.row.minimumRenewalPrice) {
                    return <p>£ {params.row.minimumRenewalPrice.toFixed(2)}</p>;
                }
                return <p>-</p>;
            },
        },
        {
            headerName: 'Edit',
            field: 'edit',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button type="button" onClick={renderEditCallOutFeeModal(params.row.id)}>
                    Edit
                </Button>
            ),
        },
        {
            headerName: 'Delete',
            field: 'delete',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button type="button" onClick={removeCallOutFee(params.row.id)}>
                    Delete
                </Button>
            ),
        },
    ];

    if (redirectTo) {
        return <Navigate to={redirectTo} />;
    }

    if (isLoading) {
        return (
            <Container>
                <SkeletonLoader repeat={3} />
            </Container>
        );
    }

    if (!pricebookData || isError) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Account Details Error</AlertTitle>
                    Unable to load account information for id something
                </Alert>
            </Container>
        );
    }

    return (
        <>
            <AddOrEditCallOutFeeModal
                open={isAddCallOutFeeModalOpen}
                closeModal={() => {
                    toggleAddCallOutFeeModalOpen();
                }}
                callOutFeeToEdit={editingCallOutFee}
                callOutFees={callOutFees}
                setCallOutFees={setCallOutFees}
            />
            <Container>
                <Header>
                    <h2>
                        {urlParams.packageId ? 'Edit package entry' : 'New package entry'} for
                        pricebook: {pricebookName}
                    </h2>
                </Header>
                <FormContainer>
                    {packagesIsLoading && <SkeletonLoader repeat={3} />}
                    {!packagesData && packagesIsError && (
                        <Alert severity="error">
                            <AlertTitle>Packages Details Error</AlertTitle>
                            Unable to load packages data
                        </Alert>
                    )}
                    {packagesData && packagesData.data.length > 0 && (
                        <TextInputMui htmlFor="packageId">
                            <TextField
                                id="packageId"
                                select
                                label="Package name"
                                variant="outlined"
                                value={packageId}
                                error={packageId === '' || shouldNotAllowSelectedPackage(packageId)}
                                helperText={renderPackageNameHelperText(packageId)}
                                disabled={!!urlParams.packageId}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPackage(e.target.value)
                                }
                            >
                                {(packagesData?.data || [])
                                    .sort((a, b) => a.id - b.id)
                                    .map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </TextInputMui>
                    )}
                    <Table
                        columns={columns}
                        rows={[...callOutFees.filter((value) => !value.deleted)]}
                        hideFooter
                    />
                    <ButtonContainer>
                        <AddButton
                            setEditingCallOutFee={setEditingCallOutFee}
                            toggleModal={toggleAddCallOutFeeModalOpen}
                        />
                        <SaveButton
                            saveFn={savePricebookEntries}
                            disabled={
                                !packageId ||
                                callOutFees.length === 0 ||
                                shouldNotAllowSelectedPackage(packageId)
                            }
                        />
                    </ButtonContainer>
                </FormContainer>
            </Container>
        </>
    );
};
