import { Button, Table, TextInput } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import { HistoryIcon, NewTabIcon } from 'assets/pandoraIcons';
import { UpdateContractInput } from 'features/contract/api/updateContract';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import { buildGoCardlessTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { getMandatoryEnvVar } from 'utils/env';
import toastConfig from 'utils/toastConfig';
import ContractHistory from '../../ContractHistory';
import WarningModal from '../../ContractModals/WarningModal';

import styles from './index.module.scss';

interface Props {
    data: Contract;
}

const gcURL = getMandatoryEnvVar('REACT_APP_GC_URL');

const GoCardless = ({ data }: Props) => {
    const updateContractMutation = useUpdateContractMutation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [gcChanges, setGcChanges] = useState<{
        go_cardless_subscription_id?: string;
        go_cardless_customer_id?: string;
        go_cardless_mandate_id?: string;
    }>({});

    const [isHistoryOpen, setIsHistoryOpen] = useState<{
        go_cardless_subscription_id: boolean;
        go_cardless_customer_id: boolean;
        go_cardless_mandate_id: boolean;
    }>({
        go_cardless_subscription_id: false,
        go_cardless_customer_id: false,
        go_cardless_mandate_id: false,
    });

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const dataToSubmit = { id: data.id, ...gcChanges } as UpdateContractInput;

            const res = await updateContractMutation.mutateAsync(dataToSubmit);
            setModalOpen(false);
            toast.success(res.data?.message, toastConfig);
            setGcChanges({});
            setCanSubmit(false);
        } catch (error) {
            toast.error((error as Error)?.message || 'Error updating account');
        }
    };

    const onEdit = (e: string, key: string) => {
        const newData = { ...gcChanges, [key]: e };
        setGcChanges(newData);
        setCanSubmit(true);
    };

    const onCancel = () => {
        setCanSubmit(false);
        setGcChanges({});
    };

    const headers = useMemo(
        () => [
            { key: 'status', value: 'Status' },
            { key: 'amount', value: 'Amount' },
            { key: 'billing_type', value: 'Billing Type' },
            { key: 'start_date', value: 'Start Date' },
        ],
        []
    );

    const getChangeText = () => {
        let change = '\n';
        Object.entries(gcChanges).forEach((val) => {
            if (val[0] === 'go_cardless_customer_id' && val[1] !== data.go_cardless_customer_id) {
                change += ` GC Customer ID will change to ${val[1]}.`;
            }
            if (val[0] === 'go_cardless_mandate_id' && val[1] !== data.go_cardless_mandate_id) {
                change += ` GC Mandate ID will change to ${val[1]}.`;
            }
            if (
                val[0] === 'go_cardless_subscription_id' &&
                val[1] !== data.go_cardless_subscription_id
            ) {
                change += ` GC Subscription ID will change to ${val[1]}.`;
            }
        });
        return <strong>{change} </strong>;
    };

    return (
        <div>
            <div className={styles.paymentHeader}>
                <h3>Go Cardless</h3>
            </div>
            <div className={styles.paymentMethodContainer}>
                <div className={styles.paymentMethods}>
                    <div className={styles.fields}>
                        <TextInput
                            id="gcMandate"
                            label="GC MANDATE"
                            placeholder={data.go_cardless_mandate_id || '-'}
                            value={gcChanges.go_cardless_mandate_id ?? data.go_cardless_mandate_id}
                            icon={
                                <>
                                    <HistoryIcon
                                        title="history"
                                        role="button"
                                        className={`${styles.historyIcon} ${
                                            isHistoryOpen.go_cardless_mandate_id ? styles.open : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsHistoryOpen({
                                                ...isHistoryOpen,
                                                go_cardless_mandate_id:
                                                    !isHistoryOpen.go_cardless_mandate_id,
                                            });
                                        }}
                                    />
                                    <a
                                        href={`${gcURL}/mandates/${
                                            data.go_cardless_mandate_id || ''
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-labelledby="gcMandate"
                                    >
                                        <NewTabIcon className={styles.icon} />
                                    </a>
                                </>
                            }
                            onChange={(e) => {
                                onEdit(e, 'go_cardless_mandate_id');
                            }}
                        />
                        <ContractHistory
                            data={data}
                            isOpen={isHistoryOpen.go_cardless_mandate_id}
                            historyKey="go_cardless_mandate_id"
                            alignment={styles.historyAlignment}
                        />
                    </div>
                    <div className={styles.fields}>
                        <TextInput
                            id="gcSubscription"
                            label="GC SUBSCRIPTION"
                            placeholder={data.go_cardless_subscription_id || '-'}
                            value={
                                gcChanges.go_cardless_subscription_id ??
                                data.go_cardless_subscription_id
                            }
                            icon={
                                <>
                                    <HistoryIcon
                                        title="history"
                                        role="button"
                                        className={`${styles.historyIcon} ${
                                            isHistoryOpen.go_cardless_subscription_id
                                                ? styles.open
                                                : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsHistoryOpen({
                                                ...isHistoryOpen,
                                                go_cardless_subscription_id:
                                                    !isHistoryOpen.go_cardless_subscription_id,
                                            });
                                        }}
                                    />
                                    <a
                                        href={`${gcURL}/subscriptions/${
                                            data.go_cardless_subscription_id || ''
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-labelledby="gcSubscription"
                                    >
                                        <NewTabIcon className={styles.icon} />
                                    </a>
                                </>
                            }
                            onChange={(e) => {
                                onEdit(e, 'go_cardless_subscription_id');
                            }}
                        />
                        <ContractHistory
                            data={data}
                            isOpen={isHistoryOpen.go_cardless_subscription_id}
                            historyKey="go_cardless_subscription_id"
                            alignment={styles.historyAlignment}
                        />
                    </div>
                    <div className={styles.fields}>
                        <TextInput
                            id="gcCustomer"
                            label="GC CUSTOMER"
                            placeholder={data.go_cardless_customer_id || '-'}
                            value={
                                gcChanges.go_cardless_customer_id ?? data.go_cardless_customer_id
                            }
                            icon={
                                <>
                                    <HistoryIcon
                                        title="history"
                                        role="button"
                                        className={`${styles.historyIcon} ${
                                            isHistoryOpen.go_cardless_customer_id ? styles.open : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsHistoryOpen({
                                                ...isHistoryOpen,
                                                go_cardless_customer_id:
                                                    !isHistoryOpen.go_cardless_customer_id,
                                            });
                                        }}
                                    />
                                    <a
                                        href={`${gcURL}/customers/${
                                            data.go_cardless_customer_id || ''
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-labelledby="gcCustomer"
                                    >
                                        <NewTabIcon className={styles.icon} />
                                    </a>
                                </>
                            }
                            onChange={(e) => {
                                onEdit(e, 'go_cardless_customer_id');
                            }}
                        />
                        <ContractHistory
                            data={data}
                            isOpen={isHistoryOpen.go_cardless_customer_id}
                            historyKey="go_cardless_customer_id"
                            alignment={styles.historyAlignment}
                        />
                    </div>
                </div>
                <div className={styles.paymentButtons}>
                    <Button onClick={onCancel} variant="outlined" isDisabled={!canSubmit}>
                        Cancel
                    </Button>
                    <Button onClick={() => setModalOpen(true)} isDisabled={!canSubmit}>
                        Confirm
                    </Button>
                </div>
                {data.go_cardless_subscription ? (
                    <div className={styles.gocardlessTable}>
                        <Table
                            data={buildGoCardlessTableData(data.go_cardless_subscription)}
                            headers={headers}
                        />
                    </div>
                ) : null}
            </div>
            {isModalOpen ? (
                <WarningModal
                    isOpen={isModalOpen}
                    setIsOpen={setModalOpen}
                    warningMessage={
                        <span>
                            You are about to make changes to this customer&apos;s Go Cardless
                            account.
                            {getChangeText()} Please cancel if this was made in error.
                        </span>
                    }
                    onCancel={handleCancel}
                    onSave={handleSubmit}
                />
            ) : null}
        </div>
    );
};

export default GoCardless;
