import Ajv from 'ajv';
import { QueryFunction } from 'react-query';

import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { CLAIM_ACTIVITIES_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { ActivityNotes, getActivityResponseSchema } from './type';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

type GetNoteActivityByClaimQueryKey = [string, { id: string }];

export const getClaimActivityNotes: QueryFunction<
    ActivityNotes,
    GetNoteActivityByClaimQueryKey
> = async ({ queryKey }) => {
    const [, { id }] = queryKey;

    const token = await getAmplifyIdTokenFromCookie();

    const response = await fetch(
        `${API_BASE_URL}/${CLAIM_ACTIVITIES_BASE_PATH}/v1/claimActivity/${id}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }
    // Fetch does not automatically throw errors
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    const ajv = new Ajv();
    const validate = ajv.compile(getActivityResponseSchema);
    const valid = validate(data);

    if (!valid) {
        throw new Error(
            `Failed to validate getClaimActivityNotes response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
