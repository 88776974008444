import { FormControl, FormLabel, TextareaAutosize } from '@mui/material';

interface Props {
    value: string;
    onChangeNote: React.Dispatch<React.SetStateAction<string>>;
}

export const NoteBox = ({ onChangeNote, value }: Props) => {
    return (
        <FormControl sx={{ marginBottom: 3 }}>
            <FormLabel htmlFor="rejection-reason-note">Notes:</FormLabel>
            <TextareaAutosize
                id="rejection-reason-note"
                name="rejection-reason-note"
                minRows={3}
                value={value}
                onChange={(e) => onChangeNote(e.target.value as string)}
            />
        </FormControl>
    );
};
