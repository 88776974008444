import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClaimById } from '../api/claim/getClaim';

export const useClaimQuery = () => {
    const urlParams = useParams();

    if (!urlParams.claimId) {
        throw new Error('claimId is required');
    }

    const { claimId } = urlParams;

    return useQuery(['claim', { id: claimId }], getClaimById, {
        enabled: !!claimId,
    });
};
