import {
    AmericanStyleFridge,
    Blender,
    CoffeeMachine,
    Cooker,
    DishWasher,
    ExtractorFan,
    ExtractorHood,
    Freezer,
    Fridge,
    FridgeFreezer,
    Gadget,
    Hob,
    Hoover,
    Microwave,
    Other,
    Oven,
    SatBox,
    TumbleDryer,
    Tv,
    WasherDryer,
    WashingMachine,
    WineCooler,
} from '@HometreeEngineering/component-library';

export const mapIconsToAppliances = (appliance: string) => {
    switch (appliance) {
        case 'American Style Fridge Freezer':
            return AmericanStyleFridge;
        case 'Fridge Freezer':
            return FridgeFreezer;
        case 'Blender':
            return Blender;
        case 'Coffee Machine':
            return CoffeeMachine;
        case 'Cooker':
        case 'Cooker Electric':
        case 'Cooker Gas':
        case 'Cooker Dual':
            return Cooker;
        case 'Dishwasher':
            return DishWasher;
        case 'Extractor Fan':
            return ExtractorFan;
        case 'Extractor Hood':
            return ExtractorHood;
        case 'Freezer':
            return Freezer;
        case 'Fridge':
            return Fridge;
        case 'Gadget':
            return Gadget;
        case 'Hob':
        case 'Hob Electric':
        case 'Hob Gas':
            return Hob;
        case 'Hoover':
        case 'Vacuum Cleaner':
            return Hoover;
        case 'Microwave':
            return Microwave;
        case 'Oven':
        case 'Oven Electric':
        case 'Oven Gas':
            return Oven;
        case 'Tumble Dryer':
        case 'Tumble Dryer Electric':
        case 'Tumble Dryer Gas':
            return TumbleDryer;
        case 'TV':
            return Tv;
        case 'Washer Dryer':
            return WasherDryer;
        case 'Washing Machine':
            return WashingMachine;
        case 'Wine Cooler':
            return WineCooler;
        case 'Satellite Box':
            return SatBox;
        case 'Multiappliances':
        case 'Steam Iron':
        default:
            return Other;
    }
};
