import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';
import { Package, packageSchema } from 'api/packages/types';

export interface Account {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    date_created: string;
    date_modified: string;
    active: number;
    author: string;
    correspondence_property_id: number;
    customer_facing_id: string;
    referral_code: string;
    notes?: string | null;
    phone1?: string | null;
    phone2?: string | null;
    marketing: number;
    salutation?: string | null;
    middle_name?: string | null;
    phone1_prefix?: string | null;
    phone2_prefix?: string | null;
    sf_account_id?: string | null;
    referral_programme?: number | null;
}

export interface Property {
    id: number;
    town: string;
    active: number;
    country: string;
    postcode: string;
    date_created: string;
    date_modified: string;
    address_line_1: string;
    type?: string | null;
    floor?: string | null;
    notes?: string | null;
    author?: string | null;
    fuel_type?: string | null;
    flue_position?: string | null;
    address_line_2?: string | null;
    address_line_3?: string | null;
    bedrooms_count?: string | null;
    radiator_count?: string | null;
    bathrooms_count?: string | null;
    last_service_date?: string | null;
    next_service?: string | null;
    boiler_age?: string | null;
}

export interface Note {
    id: number;
    date_created?: string | null;
    date_modified?: string | null;
    author?: string | null;
    active?: number | null;
    account_id?: number | null;
    property_id?: number | null;
    contract_id?: number | null;
    note?: string | null;
}

export interface GoCardlessSubscription {
    id: string;
    created_at: string;
    amount: number;
    currency: string;
    status: string;
    start_date: string;
    interval: number;
    end_date?: string | null;
    name?: string | null;
    interval_unit?: string | null;
    day_of_month?: number | null;
    month?: string | null;
    count?: number | null;
    metadata?: any | null;
    payment_reference?: string | null;
    upcoming_payments: {
        charge_date: string;
        amount: number;
    }[];
    app_fee?: string | null;
    links: {
        mandate: string;
    };
    retry_if_possible?: boolean | null;
    earliest_charge_date_after_resume?: string | null;
    billing_type?: string;
}

export interface ClaimdbClaim {
    id: number;
    type?: string | null;
    status?: string | null;
    sk_urgency?: string | null;
    date_created?: string | null;
    date_modified?: string | null;
    claim_start_date?: string | null;
    charge_total_cost?: number | null;
    sk_vulnerable_customer?: string | null;
    active?: number | null;
}

export interface Renewal {
    id?: number | null;
    type?: number | null;
    active?: number | null;
    status?: number | null;
    end_date?: string | null;
    author?: string | null;
    package_id?: number | null;
    start_date?: string | null;
    contract_id?: number | null;
    billing_type?: number | null;
    contribution?: number | null;
    date_created?: string | null;
    date_modified?: string | null;
    last_modified_by?: string | null;
    last_quoted_price?: number | null;
    package_config_id?: number | null;
    renewed_contract_id?: number | null;
    claims_accounted_for?: number | null;
    custom_renewal_discount?: number | null;
    saving?: number | null;
    cancellation_type?: number | null;
}

export interface Log {
    id: number;
    contractId?: number | null;
    date_created?: string | null;
    author?: string | null;
    active?: boolean | null;
    account_id?: number | null;
    property_id?: number | null;
    type?: number | null;
    start_date?: string | null;
    end_date?: string | null;
    cancellation_date?: string | null;
    status?: number | null;
    '3rd_party_status'?: string | null;
    '3rd_party_id'?: string | null;
    isv?: number | null;
    price?: number | null;
    payment_amount?: number | null;
    billing_type?: number | null;
    contribution?: number | null;
    package_id?: number | null;
    promo_code_id?: number | null;
    notes?: string | null;
    go_cardless_mandate_id?: string | null;
    go_cardless_customer_id?: string | null;
    go_cardless_subscription_id?: string | null;
    customer_facing_id?: string | null;
    pricebook_id?: number | null;
    ga_cid?: string | null;
    landingpage?: string | null;
    querystring?: string | null;
    http_referer?: string | null;
    webchannel?: string | null;
    cancellation_description?: string | null;
    cancellation_type?: number | null;
    utm_campaign?: string | null;
    utm_medium?: string | null;
    utm_source?: string | null;
    utm_term?: string | null;
    utm_content?: string | null;
    cancellation_status?: string | null;
    last_modified_by?: string | null;
    audit_date_created?: string | null;
    date_modified?: string | null;
    orbit_id?: string | null;
    orbit_last_modified?: string | null;
    price_report?: string | null;
    automatically_renew?: number | null;
    renewed_email_sent?: number | null;
    sk_account_id?: string | null | null;
    partner_quote_id?: string | null;
    transaction_id?: string | null;
    payments_up_to_date?: number | null;
    payment_required?: number | null;
    insurer_id?: number | null;
    representative_id?: number | null;
    underwriter_id?: number | null;
    cb_subscription_id?: string | null;
    package_config_id?: number | null;
    cb_customer_id?: string | null;
    sales_agent?: string | null;
    can_raise_claim?: number | null;
}

export interface Claim {
    id: number;
    pmsid?: number | null;
    author?: string | null;
    summary?: string | null;
    job_type?: string | null;
    pms_trade?: string | null;
    charge_final?: number | null;
    date_created?: string | null;
    date_modified?: string | null;
    claim_start_date?: string | null;
}

export interface ProductToContract {
    id: number;
    price?: number | null;
    author?: string | null;
    quantity?: string | null;
    product_id?: number | null;
    contract_id?: number | null;
    date_created?: string | null;
    product_name?: string | null; // "Gas Fire",
    date_modified?: string | null;
}

export interface Appliance {
    id: number;
    type?: string | null;
    author?: string | null;
    make_id?: number | null;
    category?: string | null;
    contract_id?: number | null;
    appliance_id?: number | null;
    date_created?: string | null;
    model_number?: string | null;
    date_modified?: string | null;
    purchase_date?: string | null;
    purchase_retailer?: string | null;
    purchase_price_gbx?: number | null;
    price_per_month_gbx?: number | null;
    make_name?: string | null;
    appliance_age?: string | null;
}
export interface Contract {
    id: number;
    date_created: string;
    date_modified: string;
    author: string;
    active: number;
    account_id: number;
    property_id: number;
    type: number;
    start_date: string;
    end_date: string;
    status: number;
    isv: number;
    payment_amount: number;
    billing_type: number;
    contribution: number;
    package_id: number;
    pricebook_id: number;
    go_cardless_mandate_id: string;
    go_cardless_customer_id: string;
    go_cardless_subscription_id: string;
    contract_id?: number;
    customer_facing_id: string;
    cancellation_date?: string | null;
    '3rd_party_status'?: number | null;
    '3rd_party_id'?: string | null;
    price?: string | null;
    promo_code_id?: number | null;
    notes?: string | null;
    ga_cid?: string | null;
    landingpage?: string | null;
    querystring?: string | null;
    http_referer?: string | null;
    webchannel?: string | null;
    cancellation_description?: string | null;
    cancellation_type?: number | null;
    utm_campaign?: string | null;
    utm_medium?: string | null;
    utm_source?: string | null;
    utm_term?: string | null;
    utm_content?: string | null;
    cancellation_status?: number | null;
    last_modified_by?: string | null;
    orbit_id?: number | null;
    orbit_last_modified?: string | null;
    price_report?: string | null;
    automatically_renew?: number | null;
    renewed_email_sent?: number | null;
    sk_account_id?: string | null;
    partner_quote_id?: string | null;
    transaction_id?: string | null;
    property: Property;
    account: Account;
    go_cardless_subscription: GoCardlessSubscription;
    alternative_accommodation: number;
    package: Package;
    relations: {
        claimdb_claims: ClaimdbClaim[];
        claims: Claim[];
        renewals: Renewal[];
        notes: Note[];
        product_to_contract: ProductToContract[];
        appliances: Appliance[];
    };
    payments_up_to_date?: number | null;
    payment_required?: number | null;
    insurer_id?: number | null;
    representative_id?: number | null;
    underwriter_id?: number | null;
    cb_subscription_id?: string | null;
    cb_customer_id?: string | null;
    package_config_id?: number | null;
    can_raise_claim?: number | null;
    log: Log[];
    payment_status?: string | null;
    sales_agent?: string | null;
    claim_customer_facing_id?: string | null;
    promocode: Promocode | null;
    is_read_only: number;
    total_cost_of_claims: number;
    annual_claim_cost_limit_gbp?: number | null;
}

export interface Promocode {
    id?: number | null;
    code?: string | null;
    type?: number | null;
    uses?: number | null;
    max_uses?: number | null;
    description?: string | null;
    active?: number | null;
    value?: number | null;
    expiry_date?: string | null;
    date_created?: string | null;
    date_modified?: string | null;
    author?: string | null;
    pricebook_id?: number | null;
    last_modified_by?: string | null;
    family?: number | null;
}

export const promocodeSchema: JSONSchemaType<Promocode> = {
    type: 'object',
    properties: {
        id: { type: 'number', nullable: true },
        code: { type: 'string', nullable: true },
        type: { type: 'number', nullable: true },
        uses: { type: 'number', nullable: true },
        max_uses: { type: 'number', nullable: true },
        description: { type: 'string', nullable: true },
        active: { type: 'number', nullable: true },
        value: { type: 'number', nullable: true },
        expiry_date: { type: 'string', nullable: true },
        date_created: { type: 'string', nullable: true },
        date_modified: { type: 'string', nullable: true },
        author: { type: 'string', nullable: true },
        pricebook_id: { type: 'number', nullable: true },
        last_modified_by: { type: 'string', nullable: true },
        family: { type: 'number', nullable: true },
    },
    required: [],
    additionalProperties: true,
};

export interface ContractResponse {
    data: Contract[];
}

export const accountSchema: JSONSchemaType<Account> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        email: { type: 'string' },
        first_name: { type: 'string' },
        last_name: { type: 'string' },
        date_created: { type: 'string' },
        date_modified: { type: 'string' },
        active: { type: 'number' },
        author: { type: 'string' },
        correspondence_property_id: { type: 'number' },
        customer_facing_id: { type: 'string' },
        referral_code: { type: 'string' },
        notes: { type: 'string', nullable: true },
        phone1: { type: 'string', nullable: true },
        phone2: { type: 'string', nullable: true },
        marketing: { type: 'number' },
        salutation: { type: 'string', nullable: true },
        middle_name: { type: 'string', nullable: true },
        phone1_prefix: { type: 'string', nullable: true },
        phone2_prefix: { type: 'string', nullable: true },
        sf_account_id: { type: 'string', nullable: true },
        referral_programme: { type: 'number', nullable: true },
    },
    required: ['id'],
};

export const propertySchema: JSONSchemaType<Property> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        town: { type: 'string' },
        active: { type: 'number' },
        country: { type: 'string' },
        postcode: { type: 'string' },
        date_created: { type: 'string' },
        date_modified: { type: 'string' },
        address_line_1: { type: 'string' },
        type: { type: 'string', nullable: true },
        floor: { type: 'string', nullable: true },
        notes: { type: 'string', nullable: true },
        author: { type: 'string', nullable: true },
        fuel_type: { type: 'string', nullable: true },
        flue_position: { type: 'string', nullable: true },
        address_line_2: { type: 'string', nullable: true },
        address_line_3: { type: 'string', nullable: true },
        bedrooms_count: { type: 'string', nullable: true },
        radiator_count: { type: 'string', nullable: true },
        bathrooms_count: { type: 'string', nullable: true },
        last_service_date: { type: 'string', nullable: true },
        next_service: { type: 'string', nullable: true },
        boiler_age: { type: 'string', nullable: true },
    },
    required: ['id', 'address_line_1', 'postcode'],
};

export const goCardlessSubscriptionSchema: JSONSchemaType<GoCardlessSubscription> = {
    type: 'object',
    properties: {
        id: { type: 'string' },
        created_at: { type: 'string' },
        amount: { type: 'number' },
        currency: { type: 'string' },
        status: { type: 'string' },
        start_date: { type: 'string' },
        interval: { type: 'number' },
        end_date: { type: 'string', nullable: true },
        name: { type: 'string', nullable: true },
        interval_unit: { type: 'string', nullable: true },
        day_of_month: { type: 'number', nullable: true },
        month: { type: 'string', nullable: true },
        count: { type: 'number', nullable: true },
        metadata: { type: 'object', nullable: true },
        payment_reference: { type: 'string', nullable: true },
        upcoming_payments: {
            type: 'array',
            items: {
                type: 'object',
                properties: { charge_date: { type: 'string' }, amount: { type: 'number' } },
                required: [],
            },
        },
        app_fee: { type: 'string', nullable: true },
        links: {
            type: 'object',
            properties: { mandate: { type: 'string' } },
            required: [],
        },
        retry_if_possible: { type: 'boolean', nullable: true },
        earliest_charge_date_after_resume: { type: 'string', nullable: true },
        billing_type: { type: 'string', nullable: true },
    },
    required: ['id'],
};

export const logSchema: JSONSchemaType<Log[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            contractId: { type: 'number', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
            author: { type: 'string', nullable: true },
            active: { type: 'boolean', nullable: true },
            account_id: { type: 'number', nullable: true },
            property_id: { type: 'number', nullable: true },
            type: { type: 'number', nullable: true },
            start_date: { type: 'string', nullable: true },
            end_date: { type: 'string', nullable: true },
            cancellation_date: { type: 'string', nullable: true },
            status: { type: 'number', nullable: true },
            '3rd_party_status': { type: 'string', nullable: true },
            '3rd_party_id': { type: 'string', nullable: true },
            isv: { type: 'number', nullable: true },
            price: { type: 'number', nullable: true },
            payment_amount: { type: 'number', nullable: true },
            billing_type: { type: 'number', nullable: true },
            contribution: { type: 'number', nullable: true },
            package_id: { type: 'number', nullable: true },
            promo_code_id: { type: 'number', nullable: true },
            notes: { type: 'string', nullable: true },
            go_cardless_mandate_id: { type: 'string', nullable: true },
            go_cardless_customer_id: { type: 'string', nullable: true },
            go_cardless_subscription_id: { type: 'string', nullable: true },
            customer_facing_id: { type: 'string', nullable: true },
            pricebook_id: { type: 'number', nullable: true },
            ga_cid: { type: 'string', nullable: true },
            landingpage: { type: 'string', nullable: true },
            querystring: { type: 'string', nullable: true },
            http_referer: { type: 'string', nullable: true },
            webchannel: { type: 'string', nullable: true },
            cancellation_description: { type: 'string', nullable: true },
            cancellation_type: { type: 'number', nullable: true },
            utm_campaign: { type: 'string', nullable: true },
            utm_medium: { type: 'string', nullable: true },
            utm_source: { type: 'string', nullable: true },
            utm_term: { type: 'string', nullable: true },
            utm_content: { type: 'string', nullable: true },
            cancellation_status: { type: 'string', nullable: true },
            last_modified_by: { type: 'string', nullable: true },
            audit_date_created: { type: 'string', nullable: true },
            orbit_id: { type: 'string', nullable: true },
            orbit_last_modified: { type: 'string', nullable: true },
            price_report: { type: 'string', nullable: true },
            automatically_renew: { type: 'number', nullable: true },
            renewed_email_sent: { type: 'number', nullable: true },
            sk_account_id: { type: 'string', nullable: true },
            partner_quote_id: { type: 'string', nullable: true },
            transaction_id: { type: 'string', nullable: true },
            payments_up_to_date: { type: 'number', nullable: true },
            payment_required: { type: 'number', nullable: true },
            insurer_id: { type: 'number', nullable: true },
            representative_id: { type: 'number', nullable: true },
            underwriter_id: { type: 'number', nullable: true },
            cb_subscription_id: { type: 'string', nullable: true },
            package_config_id: { type: 'number', nullable: true },
            cb_customer_id: { type: 'string', nullable: true },
            sales_agent: { type: 'string', nullable: true },
            can_raise_claim: { type: 'number', nullable: true },
        },
        required: ['id'],
    },
};

export const claimDbClaimSchema: JSONSchemaType<ClaimdbClaim[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            type: { type: 'string', nullable: true },
            status: { type: 'string', nullable: true },
            active: { type: 'number', nullable: true },
            sk_urgency: { type: 'string', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
            claim_start_date: { type: 'string', nullable: true },
            charge_total_cost: { type: 'number', nullable: true },
            sk_vulnerable_customer: { type: 'string', nullable: true },
        },
        required: ['id'],
    },
};

export const claimSchema: JSONSchemaType<Claim[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            pmsid: { type: 'number', nullable: true },
            job_type: { type: 'string', nullable: true },
            author: { type: 'string', nullable: true },
            summary: { type: 'string', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
            pms_trade: { type: 'string', nullable: true },
            claim_start_date: { type: 'string', nullable: true },
            charge_final: { type: 'number', nullable: true },
        },
        required: [],
    },
};

export const applianceSchema: JSONSchemaType<Appliance[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            appliance_id: { type: 'number', nullable: true },
            category: { type: 'string', nullable: true },
            author: { type: 'string', nullable: true },
            contract_id: { type: 'number', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
            make_id: { type: 'number', nullable: true },
            model_number: { type: 'string', nullable: true },
            purchase_date: { type: 'string', nullable: true },
            purchase_price_gbx: { type: 'number', nullable: true },
            price_per_month_gbx: { type: 'number', nullable: true },
            purchase_retailer: { type: 'string', nullable: true },
            type: { type: 'string', nullable: true },
            appliance_age: { type: 'string', nullable: true },
            make_name: { type: 'string', nullable: true },
        },
        required: [],
    },
};

export const renewalsSchema: JSONSchemaType<Renewal[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number', nullable: true },
            active: { type: 'number', nullable: true },
            status: { type: 'number', nullable: true },
            type: { type: 'number', nullable: true },
            saving: { type: 'number', nullable: true },
            author: { type: 'string', nullable: true },
            billing_type: { type: 'number', nullable: true },
            claims_accounted_for: { type: 'number', nullable: true },
            contract_id: { type: 'number', nullable: true },
            contribution: { type: 'number', nullable: true },
            custom_renewal_discount: { type: 'number', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
            end_date: { type: 'string', nullable: true },
            last_modified_by: { type: 'string', nullable: true },
            last_quoted_price: { type: 'number', nullable: true },
            package_config_id: { type: 'number', nullable: true },
            package_id: { type: 'number', nullable: true },
            renewed_contract_id: { type: 'number', nullable: true },
            start_date: { type: 'string', nullable: true },
            cancellation_type: { type: 'number', nullable: true },
        },
    },
};

export const notesSchema: JSONSchemaType<Note[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            active: { type: 'number', nullable: true },
            property_id: { type: 'number', nullable: true },
            account_id: { type: 'number', nullable: true },
            contract_id: { type: 'number', nullable: true },
            author: { type: 'string', nullable: true },
            note: { type: 'string', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
        },
        required: [],
    },
};

export const productToContractSchema: JSONSchemaType<ProductToContract[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            id: { type: 'number' },
            price: { type: 'number', nullable: true },
            quantity: { type: 'string', nullable: true },
            product_name: { type: 'string', nullable: true },
            product_id: { type: 'number', nullable: true },
            contract_id: { type: 'number', nullable: true },
            author: { type: 'string', nullable: true },
            date_created: { type: 'string', nullable: true },
            date_modified: { type: 'string', nullable: true },
        },
        required: [],
    },
};

export const contractSchema: JSONSchemaType<ContractResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    author: { type: 'string' },
                    active: { type: 'number' },
                    account_id: { type: 'number' },
                    property_id: { type: 'number' },
                    contract_id: { type: 'number', nullable: true },
                    type: { type: 'number' },
                    start_date: { type: 'string' },
                    end_date: { type: 'string' },
                    status: { type: 'number' },
                    isv: { type: 'number' },
                    payment_amount: { type: 'number' },
                    billing_type: { type: 'number' },
                    contribution: { type: 'number' },
                    package_id: { type: 'number' },
                    pricebook_id: { type: 'number' },
                    go_cardless_mandate_id: { type: 'string' },
                    go_cardless_customer_id: { type: 'string' },
                    go_cardless_subscription_id: { type: 'string' },
                    customer_facing_id: { type: 'string' },
                    cancellation_date: { type: 'string', nullable: true },
                    '3rd_party_status': { type: 'number', nullable: true },
                    '3rd_party_id': { type: 'string', nullable: true },
                    price: { type: 'string', nullable: true },
                    promo_code_id: { type: 'number', nullable: true },
                    notes: { type: 'string', nullable: true },
                    ga_cid: { type: 'string', nullable: true },
                    landingpage: { type: 'string', nullable: true },
                    querystring: { type: 'string', nullable: true },
                    http_referer: { type: 'string', nullable: true },
                    webchannel: { type: 'string', nullable: true },
                    cancellation_description: { type: 'string', nullable: true },
                    cancellation_type: { type: 'number', nullable: true },
                    utm_campaign: { type: 'string', nullable: true },
                    utm_medium: { type: 'string', nullable: true },
                    utm_source: { type: 'string', nullable: true },
                    utm_term: { type: 'string', nullable: true },
                    utm_content: { type: 'string', nullable: true },
                    cancellation_status: { type: 'number', nullable: true },
                    last_modified_by: { type: 'string', nullable: true },
                    orbit_id: { type: 'number', nullable: true },
                    orbit_last_modified: { type: 'string', nullable: true },
                    price_report: { type: 'string', nullable: true },
                    automatically_renew: { type: 'number', nullable: true },
                    renewed_email_sent: { type: 'number', nullable: true },
                    sk_account_id: { type: 'string', nullable: true },
                    partner_quote_id: { type: 'string', nullable: true },
                    transaction_id: { type: 'string', nullable: true },
                    payment_status: { type: 'string', nullable: true },
                    property: propertySchema,
                    account: accountSchema,
                    go_cardless_subscription: goCardlessSubscriptionSchema,
                    alternative_accommodation: { type: 'number' },
                    payments_up_to_date: { type: 'number', nullable: true },
                    payment_required: { type: 'number', nullable: true },
                    insurer_id: { type: 'number', nullable: true },
                    representative_id: { type: 'number', nullable: true },
                    underwriter_id: { type: 'number', nullable: true },
                    cb_subscription_id: { type: 'string', nullable: true },
                    cb_customer_id: { type: 'string', nullable: true },
                    package_config_id: { type: 'number', nullable: true },
                    can_raise_claim: { type: 'number', nullable: true },
                    sales_agent: { type: 'string', nullable: true },
                    claim_customer_facing_id: { type: 'string', nullable: true },
                    package: packageSchema,
                    log: logSchema,
                    relations: {
                        type: 'object',
                        properties: {
                            claimdb_claims: claimDbClaimSchema,
                            renewals: renewalsSchema,
                            claims: claimSchema,
                            notes: notesSchema,
                            product_to_contract: productToContractSchema,
                            appliances: applianceSchema,
                        },
                        required: [],
                        additionalProperties: true,
                    },
                    promocode: {
                        type: 'object',
                        anyOf: [promocodeSchema, {}],
                    },
                    is_read_only: { type: 'number' },
                    total_cost_of_claims: { type: 'number' },
                    annual_claim_cost_limit_gbp: { type: 'number', nullable: true },
                },
                required: ['id', 'date_created'],
            },
        },
    },
    required: ['data'],
};

export type GetContractResponse = PandoraErrorResponse | ContractResponse;

export const getContractResponseSchema: JSONSchemaType<GetContractResponse> = {
    type: 'object',
    anyOf: [contractSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
