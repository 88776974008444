import { Header as GenericHeader } from 'components/Header';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import { useToggle } from 'hooks/useToggle';
import { DatabaseJobs } from 'features/claim/api/jobs/type';
import { CloseClaimButton } from './CloseClaimButton';
import { CloseClaimModal } from '../CloseClaimModal';
import { EditClaimStatusButton } from './EditClaimStatusButton';
import { EditStatusModal } from '../EditStatusModal';
import { AuthoriseInvoiceButton } from './AuthoriseInvoiceButton';
import { AuthoriseInvoiceModal } from '../AuthoriseInvoiceModal';

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`;

interface Props {
    jobs: DatabaseJobs | undefined;
}

export const Header = ({ jobs }: Props) => {
    const [searchParams] = useSearchParams();
    const canAuthoriseInvoice = searchParams.get('inv_auth');

    const [isModalOpen, toggleIsModalOpen] = useToggle(false);
    const [isStatusModalOpen, toggleIsStatusModalOpen] = useToggle(false);
    const [isAuthoriseInvoiceModalOpen, toggleIsAuthoriseInvoiceModalOpen] = useToggle(false);

    const isReadyToBeInvoiced =
        jobs &&
        jobs.jobs.filter(
            (job) =>
                (job.sk_job_status === 'Complete' || job.sk_job_status === 'Cancelled') &&
                !job.invoiced
        );

    return (
        <>
            <CloseClaimModal
                open={isModalOpen}
                closeModal={() => {
                    toggleIsModalOpen();
                }}
            />
            <EditStatusModal
                open={isStatusModalOpen}
                closeModal={() => {
                    toggleIsStatusModalOpen();
                }}
            />
            {isReadyToBeInvoiced && isReadyToBeInvoiced.length > 0 && (
                <AuthoriseInvoiceModal
                    open={isAuthoriseInvoiceModalOpen}
                    closeModal={() => {
                        toggleIsAuthoriseInvoiceModalOpen();
                    }}
                    jobs={isReadyToBeInvoiced}
                />
            )}

            <GenericHeader
                text="Claim"
                buttons={
                    <ButtonContainer>
                        {canAuthoriseInvoice === '1' &&
                        isReadyToBeInvoiced &&
                        isReadyToBeInvoiced.length ? (
                            <AuthoriseInvoiceButton
                                toggleModal={toggleIsAuthoriseInvoiceModalOpen}
                            />
                        ) : (
                            <></>
                        )}
                        <EditClaimStatusButton toggleModal={toggleIsStatusModalOpen} />
                        <CloseClaimButton toggleModal={toggleIsModalOpen} />
                    </ButtonContainer>
                }
            />
        </>
    );
};
