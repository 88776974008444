import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray_800};
    padding: 1rem 3rem;
`;

const StyledText = styled.p`
    color: ${(props) => props.theme.colors.gray_000};
    font-weight: 500;
    font-size: 1.5rem;
    opacity: 1;
`;

interface Props {
    text: string;
    buttons?: React.ReactNode;
}
export const Header = ({ text, buttons }: Props) => {
    return (
        <StyledHeader>
            <StyledText>{text}</StyledText>
            {buttons}
        </StyledHeader>
    );
};
