import { GridCellValue, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import styled from 'styled-components';

const AuthoriseSelectInput = styled.select`
    width: 70%;
    :focus {
        outline: none;
    }
`;

type HandleChangeType = (
    event: React.ChangeEvent<HTMLSelectElement>,
    jobId: string | undefined
) => void;

const generateInvoiceTableColumns = (
    authorseInputHandleChange: HandleChangeType,
    selectedJobs: any
) => [
    {
        headerName: 'Invoice',
        field: 'id',
        width: 150,
        renderCell: (params: GridRenderCellParams<string>) => (
            <AuthoriseSelectInput
                id="authorise"
                onChange={(e) => authorseInputHandleChange(e, params.value)}
                data-testid="authorise-select"
                defaultValue={
                    // eslint-disable-next-line no-nested-ternary
                    params.row.invoice_authorised === true
                        ? 'yes'
                        : params.row.invoice_authorised === false
                        ? 'no'
                        : ''
                }
            >
                <option
                    value=""
                    disabled={
                        selectedJobs.some((job: any) => job.jobName === params.row.sk_job_name) ||
                        params.row.invoice_authorised !== null
                    }
                >
                    {' '}
                </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </AuthoriseSelectInput>
        ),
    },
    {
        headerName: 'Job Name',
        field: 'sk_job_name',
        width: 150,
        renderCell: (params: GridRenderCellParams<string>) => (
            <a
                href={`${process.env.REACT_APP_SKEDULO_DOMAIN}/job/${params.row.sk_job_id}`}
                target="_blank"
                rel="noreferrer"
            >
                {params.value}
            </a>
        ),
    },
    {
        headerName: 'Status',
        field: 'sk_job_status',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
            `${params.value || '-'}`,
    },
    {
        headerName: 'Start Date',
        field: 'sk_actual_start',
        width: 150,
        type: 'date',
        valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
            new Date(params.value as string).toLocaleDateString('en-UK'),
    },
    {
        headerName: 'Visit Type',
        field: 'sk_visit_type',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
            `${params.value || '-'}`,
    },
    {
        headerName: 'Engineer Name',
        field: 'sk_resource_name',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
            `${params.value || '-'}`,
    },
    {
        headerName: 'Total Cost',
        field: 'charge_total_cost',
        width: 150,
        renderCell: (params: GridRenderCellParams<string>): GridCellValue =>
            params.value
                ? `£${(+params.value || 0) / 100 - +params.row.sk_w1_order_net_amount || 0}`
                : '£0',
    },
];

export default generateInvoiceTableColumns;
