import { ClaimStatus } from 'types/enum/ClaimStatus';
import { useClaimQuery } from 'features/claim/hooks/useClaimQuery';
import { DatabaseClaim } from 'features/claim/api/claim/type';
import { SecondaryButton } from 'components/buttons';

interface Props {
    toggleModal: () => void;
}

const isDisabled = (isLoading: boolean, data?: DatabaseClaim) => {
    if (isLoading) {
        return true;
    }
    const id = data?.claim_status_id;
    const hometreeNotFixingAllowed =
        (id === ClaimStatus.HOMETREE_NOT_FIX && data?.claim_insurer_approval_required === 1) ||
        id !== ClaimStatus.HOMETREE_NOT_FIX;

    return (
        id !== ClaimStatus.ENGINEER_TO_SCHEDULE_FOLLOW_ON &&
        id !== ClaimStatus.INVESTIGATION_REQUIRED &&
        hometreeNotFixingAllowed &&
        // START - TEMP fix for BNW-1400 until Parts Orders work done
        id !== ClaimStatus.CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY &&
        id !== ClaimStatus.ORDER_PARTS &&
        id !== ClaimStatus.SPECIAL_PARTS_REQUIRED &&
        // END - TEMP fix for BNW-1400 until Parts Orders work done
        // allow updating to hometree not fixing if PENDING_INSURER_APPROVAL
        id !== ClaimStatus.PENDING_INSURER_APPROVAL
    );
};

export const EditClaimStatusButton = ({ toggleModal }: Props) => {
    const { data, isLoading } = useClaimQuery();

    return (
        <SecondaryButton onClick={() => toggleModal()} disabled={isDisabled(isLoading, data)}>
            Update
        </SecondaryButton>
    );
};
