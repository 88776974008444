import { TextInput } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import styles from './index.module.scss';

const Marketing = ({ data }: { data: Contract | undefined }) => {
    return (
        <div className={styles.container}>
            <div className={`${styles.section} ${styles.placeholderInput}`}>
                <div className={styles.gridContainer}>
                    <TextInput
                        id="landingPage"
                        label="LANDING PAGE"
                        placeholder={data?.landingpage ?? ''}
                        value={data?.landingpage ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="httpReferer"
                        label="HTTP REFERER"
                        placeholder={data?.http_referer ?? ''}
                        value={data?.http_referer ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="gaCid"
                        label="GA CID"
                        placeholder={data?.ga_cid ?? ''}
                        value={data?.ga_cid ?? ''}
                        readOnly
                        disabled
                    />

                    <TextInput
                        id="queryString"
                        label="QUERY STRING"
                        placeholder={data?.querystring ?? ''}
                        value={data?.querystring ?? ''}
                        readOnly
                        disabled
                    />

                    <TextInput
                        id="campaign"
                        label="CAMPAIGN"
                        placeholder={data?.utm_campaign ?? ''}
                        value={data?.utm_campaign ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="medium"
                        label="MEDIUM"
                        placeholder={data?.utm_medium ?? ''}
                        value={data?.utm_medium ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="source"
                        label="SOURCE"
                        placeholder={data?.utm_source ?? ''}
                        value={data?.utm_source ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="term"
                        label="TERM"
                        placeholder={data?.utm_term ?? ''}
                        value={data?.utm_term ?? ''}
                        readOnly
                        disabled
                    />

                    <TextInput
                        id="content"
                        label="CONTENT"
                        placeholder={data?.utm_content ?? ''}
                        value={data?.utm_content ?? ''}
                        readOnly
                        disabled
                    />
                    <TextInput
                        id="salesAgent"
                        label="SALES AGENT"
                        placeholder={data?.sales_agent ?? ''}
                        value={data?.sales_agent ?? ''}
                        readOnly
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

export default Marketing;
