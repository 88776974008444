import { Table } from '@HometreeEngineering/component-library';
import { ClaimdbClaim } from 'api/contract/types';
import { CardboardIcon } from 'assets/pandoraIcons';
import { buildClaimsTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import styles from '../../index.module.scss';

const ClaimsTable = ({ claims }: { claims: ClaimdbClaim[] }) => {
    const headers = useMemo(
        () => [
            { key: 'claim_id', value: 'Claim ID' },
            { key: 'type', value: 'Type' },
            { key: 'status', value: 'Status' },
            { key: 'urgency', value: 'Urgency' },
            { key: 'start_date', value: 'Start Date' },
            { key: 'vulnerable_customer', value: 'Vulnerable Customer' },
            { key: 'cost', value: 'Cost' },
        ],
        []
    );

    const data = useMemo(() => buildClaimsTableData(claims), [claims]);

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                data={data}
                customStyle={styles.differentHeader}
                title="Claims"
                icon={<CardboardIcon />}
                headerVariant="orange"
            />
        </div>
    );
};

export default ClaimsTable;
