import { DataGrid, GridColumns, GridInitialState } from '@mui/x-data-grid';
import styled from 'styled-components';

interface Props {
    rows: Record<string, any>[];
    columns: GridColumns;
    hideFooter: boolean;
    defaultSort?: { column: string; direction: 'asc' | 'desc' };
    highlightKey?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '800',
    },
    '& .live': {
        backgroundColor: '#d4ffba',
        '&:hover': {
            backgroundColor: '#d4ffba !important',
        },
        '&.Mui-selected': {
            backgroundColor: '#d4ffba !important',
        },
    },
}));

export const Table = ({ rows, columns, hideFooter, defaultSort, highlightKey }: Props) => {
    let initialState: GridInitialState = { pagination: { pageSize: 100 } };
    if (defaultSort) {
        initialState = {
            sorting: {
                sortModel: [{ field: defaultSort.column, sort: defaultSort.direction }],
            },
        };
    }

    return (
        <StyledDataGrid
            rows={rows}
            columns={columns}
            columnBuffer={columns.length} // columnBuffer prop required for tests to ensure all columns get rendered
            hideFooter={hideFooter}
            initialState={initialState}
            getRowClassName={(params) => (String(params.row.key) === highlightKey ? 'live' : '')}
            autoHeight
        />
    );
};
