import { FormControl, InputLabel } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useClaimQuery } from 'features/claim/hooks/useClaimQuery';
import { DatabaseClaim } from 'features/claim/api/claim/type';
import { ClaimStatus } from 'types/enum/ClaimStatus';

interface Props {
    value: number;
    onChange: React.Dispatch<React.SetStateAction<number>>;
}

interface ClaimStatusOption {
    id: number;
    name: string;
}

const claimStatuses: Record<string, ClaimStatusOption> = {
    'Engineer to Schedule Follow-On': {
        id: ClaimStatus.ENGINEER_TO_SCHEDULE_FOLLOW_ON,
        name: 'Engineer to Schedule Follow-On',
    },
    'Investigation Required': {
        id: ClaimStatus.INVESTIGATION_REQUIRED,
        name: 'Investigation Required',
    },
    'Order Parts': { id: ClaimStatus.ORDER_PARTS, name: 'Order Parts' },
    'Hometree Not Fixing': { id: ClaimStatus.HOMETREE_NOT_FIX, name: 'Hometree Not Fixing' },
    'Special Parts Required': {
        id: ClaimStatus.SPECIAL_PARTS_REQUIRED,
        name: 'Special Parts Required',
    },
    // START - TEMP fix for BNW-1400 until Parts Orders work done
    'Confirm Engineer and Customer Availability': {
        id: ClaimStatus.CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY,
        name: 'Confirm Engineer and Customer Availability',
    },
    'Schedule Initial Visit or Reattend': {
        id: ClaimStatus.SCHEDULE_INITIAL_VISIT_OR_REATTEND,
        name: 'Schedule Initial Visit or Reattend',
    },
    'Pending Insurer Approval': {
        id: ClaimStatus.PENDING_INSURER_APPROVAL,
        name: 'Pending Insurer Approval',
    },
    // END - TEMP fix for BNW-1400 until Parts Orders work done
};

export const invalidStatus = (claim?: DatabaseClaim) => {
    return !claim || claim.claim_status_id === null || claim.claim_status_id === undefined;
};

export const StatusSelect = ({ value, onChange }: Props) => {
    const { data } = useClaimQuery();

    // TODO enum to attach meaning to status numbers
    const availableStatus = (status: number) => {
        switch (status) {
            case ClaimStatus.ENGINEER_TO_SCHEDULE_FOLLOW_ON:
                return [claimStatuses['Investigation Required']];
            case ClaimStatus.INVESTIGATION_REQUIRED:
                return [
                    claimStatuses['Hometree Not Fixing'],
                    claimStatuses['Order Parts'],
                    claimStatuses['Schedule Initial Visit or Reattend'],
                ];
            case ClaimStatus.HOMETREE_NOT_FIX:
                return [claimStatuses['Order Parts']];
            // START - TEMP fix for BNW-1400 until Parts Orders work done
            case ClaimStatus.CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY:
                return [claimStatuses['Order Parts']];
            case ClaimStatus.ORDER_PARTS:
                return [
                    claimStatuses['Hometree Not Fixing'],
                    claimStatuses['Confirm Engineer and Customer Availability'],
                    claimStatuses['Special Parts Required'],
                ];
            case ClaimStatus.SPECIAL_PARTS_REQUIRED:
                return [
                    claimStatuses['Hometree Not Fixing'],
                    claimStatuses['Confirm Engineer and Customer Availability'],
                ];
            case ClaimStatus.PENDING_INSURER_APPROVAL:
                return [claimStatuses['Hometree Not Fixing']];
            // END - TEMP fix for BNW-1400 until Parts Orders work done
            default:
                return [];
        }
    };

    const renderMenuItem = (claim?: DatabaseClaim) => {
        if (invalidStatus(claim)) {
            return null;
        }

        return availableStatus(claim!.claim_status_id!) // TS should be able to see that claim and claim_status_id have been null checked...
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((el) => {
                return (
                    <MenuItem key={el.id} value={el.id}>
                        {el.name}
                    </MenuItem>
                );
            });
    };

    return (
        <FormControl sx={{ marginTop: 5, marginBottom: 5 }}>
            <InputLabel id="claim-status">Claim Status</InputLabel>
            <MuiSelect
                labelId="claim-status"
                id="claim-status-select"
                label="Claim Status"
                onChange={(e) => onChange(e.target.value as number)}
                defaultValue={data?.claim_status_id || 0}
                value={value}
            >
                <MenuItem value={0}>
                    <em>Please select</em>
                </MenuItem>
                {renderMenuItem(data)}
            </MuiSelect>
        </FormControl>
    );
};
