import { Table } from '@HometreeEngineering/component-library';
import { Property } from 'api/contract/types';
import { HomeIcon } from 'assets/pandoraIcons';
import { buildPropertyTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import { getPandoraDomain } from 'utils/getPandoraDomain';
import styles from '../index.module.scss';

const PropertyTable = ({ property }: { property: Property }) => {
    const headers = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'address', value: 'Address' },
            { key: 'town', value: 'Town' },
            { key: 'postcode', value: 'Postcode' },
            { key: 'last_service', value: 'Last Service' },
            { key: 'next_service', value: 'Next Service' },
            { key: 'boiler_age', value: 'Boiler Age' },
        ],
        []
    );
    const data = useMemo(() => buildPropertyTableData(property), [property]);
    const pandoraDomain = getPandoraDomain(process.env.REACT_APP_STAGE);

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <h5>LINKED PROPERTY {property.id}</h5>
                <a
                    aria-label="navigate to property page"
                    className={styles.linkIcon}
                    href={`${pandoraDomain}/property/${property.id}`}
                >
                    <HomeIcon />
                </a>
            </div>
            <Table headers={headers} data={data} customStyle={styles.differentHeader} />
        </div>
    );
};

export default PropertyTable;
