export enum ClaimStatus {
    ENGINEER_TO_SCHEDULE_FOLLOW_ON = 0,
    SCHEDULE_INITIAL_VISIT_OR_REATTEND = 1,
    INVESTIGATION_REQUIRED = 2,
    CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY = 3,
    ENGINEER_TO_ACCEPT_JOB = 4,
    SENT_TO_SME = 5,
    UPCOMING = 6,
    LATE = 7,
    MISSED = 8,
    CHECK_IF_JOB_IS_COMPLETE = 9,
    CHECK_SME_HAS_COMPLETED_JOB = 10,
    CLOSED = 11,
    UNKNOWN = 12,
    ORDER_PARTS = 13,
    HOMETREE_NOT_FIX = 14,
    CHECK_PMS_HAS_COMPLETED_CLAIM = 15,
    SPECIAL_PARTS_REQUIRED = 16,
    PENDING_INSURER_APPROVAL = 17,
}
