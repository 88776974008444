import { Table } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import { HomeIcon, OpenRentIcon, Tools } from 'assets/pandoraIcons';
import { ContractBillingTypesResponse, ContractTypesResponse } from 'features/contract/api/types';
import { getContractTypeName } from 'features/contract/utils/getContractTypeName';
import { buildContractTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import contractStyles from './index.module.scss';

const ContractTable = ({
    contractData,
    typesData,
    billingTypesData,
}: {
    contractData: Contract;
    typesData: ContractTypesResponse | undefined;
    billingTypesData: ContractBillingTypesResponse | undefined;
}) => {
    const headers = useMemo(
        () => [
            { key: 'package', value: 'Package' },
            { key: 'startDate', value: 'Start Date' },
            { key: 'monthlyPrice', value: 'Monthly Price' },
            { key: 'billingAmount', value: 'Billing Amount' },
            { key: 'claims', value: 'Claims' },
            { key: 'renewalPrice', value: 'Renewal Price' },
            { key: 'billingType', value: 'Billing Type' },
            { key: 'callOutFee', value: 'Call-Out Fee' },
        ],
        []
    );
    const tableData = useMemo(
        () => buildContractTableData(contractData, billingTypesData),
        [contractData, billingTypesData]
    );

    const contractTypeForContract = getContractTypeName(typesData?.data, contractData);

    const isOpenrent = contractData.is_read_only === 1;
    const title = isOpenrent
        ? 'OpenRent Contract'
        : `${contractData.package?.is_insurance === 1 ? 'Insurance' : 'Maintenance'}${
              contractTypeForContract ? ` - ${contractTypeForContract}` : ''
          }`;

    let icon;
    let headerVariant: 'blue' | 'green' | 'orange' | undefined;
    let customStyle;

    if (isOpenrent) {
        icon = <OpenRentIcon className={contractStyles.openRentIcon} />;
        headerVariant = 'blue';
        customStyle = contractStyles.firstTdStyle;
    } else if (contractData.package?.is_insurance === 1) {
        icon = <HomeIcon className={contractStyles.icon} />;
        headerVariant = undefined;
    } else {
        icon = <Tools className={contractStyles.icon} />;
        headerVariant = 'green';
    }

    return (
        <div className={contractStyles.contractTableContainer}>
            <Table
                headerVariant={headerVariant}
                headers={headers}
                data={tableData}
                title={title}
                customStyle={customStyle}
                icon={icon}
            />
        </div>
    );
};

export default ContractTable;
