import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { patchClaimById } from '../api/claim/patchClaim';

export const useClaimMutation = () => {
    const urlParams = useParams();
    if (!urlParams.claimId) {
        throw new Error('claimId is required');
    }

    const queryClient = useQueryClient();

    return useMutation(patchClaimById, {
        onSuccess: (data) => {
            queryClient.setQueryData(['claim', { id: urlParams.claimId }], data);
        },
    });
};
