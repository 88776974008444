import { Button, Spinner } from '@HometreeEngineering/component-library';
import styles from './index.module.scss';

interface Props {
    handleSubmit: (e: any) => Promise<void>;
    isSubmitting: boolean;
    isSubmitButtonDisabled: boolean;
    setIsOpen?: (isOpen: boolean) => void;
}

const ModalButtons = ({ setIsOpen, handleSubmit, isSubmitting, isSubmitButtonDisabled }: Props) => (
    <div className={styles.buttonWrapper}>
        {setIsOpen && (
            <Button
                type="button"
                onClick={() => setIsOpen(false)}
                isDisabled={isSubmitting || isSubmitButtonDisabled}
                variant="outlined"
                customStyle={styles.backButton}
            >
                Back
            </Button>
        )}
        <Button
            type="submit"
            onClick={handleSubmit}
            isDisabled={isSubmitting || isSubmitButtonDisabled}
            variant="primary"
        >
            Submit
        </Button>
        {isSubmitting ? <Spinner /> : null}
    </div>
);

export default ModalButtons;
