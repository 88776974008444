import styled from 'styled-components';

interface MoreInfoButtonProps {
    highlight: boolean;
}

export const Container = styled.div`
    padding: 0 3rem;
`;

export const Label = styled.label`
    color: ${({ theme }) => theme.colors.dark_green};
    font-weight: 600;
    font-size: 1.2rem;
    accent-color: #807e7e;
`;

export const MoreInfoButton = styled.button<MoreInfoButtonProps>`
    border: ${({ theme }) => `1px solid  ${theme.colors.dark_green}`};
    font-size: 1rem;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center;
    bor: aliceblue;
    margin-left: 10px;
    border-: border-box;
    font-size: 1rem;
    font-weight: 400;
    background-color: ${({ highlight, theme }) =>
        highlight ? theme.colors.primary_green : 'inherit'};
`;

export const MoreInfo = styled.p`
    color: ${({ theme }) => theme.colors.dark_green};
    border: 3px solid 172B02;
    box-shadow: 1px 2px 4px #172b02;
    border-radius: 10px;
    padding: 20px;
    max-width: 250px;
`;
