import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { Controller } from 'features/pricebook/api/type';
import { QueryFunction } from 'react-query';
import { CONTROLLER_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

type GetPackageByQueryKey = [string, {}];

export const getController: QueryFunction<Controller, GetPackageByQueryKey> = async () => {
    const token = await getAmplifyIdTokenFromCookie();
    const response = await fetch(`${API_BASE_URL}/${CONTROLLER_BASE_PATH}/controller`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            // mode: 'no-cors',
        },
    });

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.json();

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
