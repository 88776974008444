import { PrimaryButton } from 'components/buttons';

interface Props {
    toggleModal: () => void;
}

export const AddButton = ({ toggleModal }: Props) => {
    return (
        <PrimaryButton
            onClick={() => {
                toggleModal();
            }}
        >
            Add new pricebook
        </PrimaryButton>
    );
};
