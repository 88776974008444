export const CLAIM_ACTIVITIES_BASE_PATH = 'claimActivities';
export const JOBS_BASE_PATH = 'jobs';
export const CLAIMS_BASE_PATH = 'claims';
export const CONTRACTS_BASE_PATH = 'contracts';
export const PRICEBOOKS_BASE_PATH = 'pricebooks';
export const PACKAGES_BASE_PATH = 'packages';
export const CONTROLLER_BASE_PATH = 'controller';
export const PROMOCODE_BASE_PATH = 'promocodes-public';
export const ACCOUNTS_BASE_PATH = 'accounts';
export const SEARCH_BASE_PATH = 'search';
