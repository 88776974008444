import { useQuery } from 'react-query';
import { searchItems } from '.';

export const useSearchItemsQuery = (searchPath: string, searchQuery: string) => {
    return useQuery(['search', searchPath, searchQuery], searchItems, {
        enabled: !!searchQuery,
        keepPreviousData: true,
        staleTime: 1000,
    });
};
