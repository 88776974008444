import { MenuBar } from 'components/menu-bar';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getCookieDomain } from 'utils/getCookieDomain';
import { usePricebookQuery } from '../hooks/usePricebookQuery';
import { usePricebooksQuery } from '../hooks/usePricebooksQuery';
import { Header } from './Header';
import { NewPricebookEntryForm } from './NewPricebookEntryForm';
import { PriceByCallOutFee } from './PriceByCallOutFee';
import { PricebookDetails } from './PricebookDetails';
import { PricebookList } from './PricebookList';
import { CallOutFee } from './types';

const Container = styled.main`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.gray_000};
`;
export const Pricebook = () => {
    const { data, isLoading, isError } = usePricebookQuery({});
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header />
                <PricebookDetails data={data} isLoading={isLoading} isError={isError} />
            </Container>
        </>
    );
};

export const Pricebooks = () => {
    const { data, isLoading, isError, refetch } = usePricebooksQuery();
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header />
                <PricebookList
                    data={data}
                    isLoading={isLoading}
                    isError={isError}
                    refetch={refetch}
                />
            </Container>
        </>
    );
};

export const NewPricebookEntry = () => {
    const { data: pricebookData, isLoading, isError } = usePricebookQuery({});
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header />
                <NewPricebookEntryForm
                    pricebookData={pricebookData}
                    isLoading={isLoading}
                    isError={isError}
                />
            </Container>
        </>
    );
};

export const EditPricebookEntry = () => {
    const [enableRefetch, setEnableRefetch] = useState(true);
    const {
        data: pricebookData,
        isLoading,
        isError,
    } = usePricebookQuery({ refetch: enableRefetch });
    const urlParams = useParams();
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    const callOutFeesEntries = pricebookData?.data[0].relations?.pricebook_entries
        ?.filter((entry) => {
            return (
                entry.active === 1 &&
                entry.package_id === Number(urlParams.packageId) &&
                (entry.contribution || entry.contribution === 0) &&
                (entry.price || entry.price === 0)
            );
        })
        .map((entry) => {
            return {
                id: entry.id,
                callOutFee: entry.contribution,
                price: entry.price / 100,
                minimumRenewalPrice: entry.minimum_renewal_price
                    ? entry.minimum_renewal_price / 100
                    : null,
            } as CallOutFee;
        });

    if (!isLoading && !isError && enableRefetch) {
        setEnableRefetch(false);
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header />
                <NewPricebookEntryForm
                    pricebookData={pricebookData}
                    callOutFeesEntries={callOutFeesEntries}
                    isLoading={isLoading}
                    isError={isError}
                />
            </Container>
        </>
    );
};

export const PricebookEntry = () => {
    const { data, isLoading, isError } = usePricebookQuery({});
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <MenuBar />
            <Container>
                <Header />
                <PriceByCallOutFee data={data} isLoading={isLoading} isError={isError} />
            </Container>
        </>
    );
};
