import { Contract } from 'api/contract/types';
import ChargeBee from './ChargeBee';
import GoCardless from './GoCardless';
import styles from './index.module.scss';

const PaymentOptions = ({ data }: { data: Contract | undefined }) => {
    return (
        <>
            {data ? (
                <div className={styles.container}>
                    <GoCardless data={data} />
                    <ChargeBee data={data} />
                </div>
            ) : null}
        </>
    );
};

export default PaymentOptions;
