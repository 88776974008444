import { InfoBox } from 'components/InfoBox';
import { getThirdPartyIdType } from 'features/contract/utils/getThirdPartyIdType';
import styles from './index.module.scss';

export interface Props {
    value: string;
    type: string;
}

export const ThirdPartyId: React.FC<Props> = ({ value, type }) => {
    const thirdPartyIdType = getThirdPartyIdType(type);

    if (!thirdPartyIdType) {
        return null;
    }

    return (
        <InfoBox heading="Third party ID" background="green">
            <section className={styles.contentContainer}>
                <p className={styles.contentValue}>{value}</p>
                <p className={styles.contentType}>{thirdPartyIdType}</p>
            </section>
        </InfoBox>
    );
};
