import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { CreatePricebookPayload } from 'features/pricebook/api/type';
import { MutationFunction } from 'react-query';
import { PRICEBOOKS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

type NewPricebookQueryKey = { name: string };

export const newPricebook: MutationFunction<CreatePricebookPayload, NewPricebookQueryKey> = async (
    queryKey
) => {
    const { name } = queryKey;
    const token = await getAmplifyIdTokenFromCookie();
    const response = await fetch(
        `${API_BASE_URL}/${PRICEBOOKS_BASE_PATH}/model/pricebooks/create`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                name,
                landlord_addition: 450,
                annual_multiplier: 1,
            }),
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.json();

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
