import { Modal } from '@HometreeEngineering/component-library';
import { addYears, format, sub } from 'date-fns';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import ModalButtons from '../ModalButtons';
import styles from './index.module.scss';

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    contractId: number;
    customerFacingId: string;
    startDate: string;
    endDate: string;
}

const ChangeDatesModal = ({
    isOpen,
    setIsOpen,
    contractId,
    customerFacingId,
    startDate: contractStartDate,
    endDate: contractEndDate,
}: Props) => {
    const [startDate, setStartDate] = useState<string>(
        format(new Date(contractStartDate), 'yyyy-MM-dd') || ''
    );
    const [endDate, setEndDate] = useState<string>(
        format(new Date(contractEndDate), 'yyyy-MM-dd') || ''
    );
    const [isChangingDates, setIsChangingDates] = useState<boolean>(false);

    const updateContractMutation = useUpdateContractMutation();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsChangingDates(true);
        try {
            const data = {
                end_date: format(new Date(endDate), 'yyyy-MM-dd'),
                start_date: format(new Date(startDate), 'yyyy-MM-dd'),
                id: contractId,
            };
            await updateContractMutation.mutateAsync(data);
            setIsChangingDates(false);
            setIsOpen(false);
            toast.success('Contract dates updated successfully');
        } catch (error) {
            setIsChangingDates(false);
            setIsOpen(false);
            toast.error((error as Error)?.message || 'Error changing contract dates');
        }
    };

    const isButtonsDisabled = !startDate || !endDate;
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            style={{ backgroundColor: 'white', width: '90%', maxWidth: '450px' }}
        >
            <div className={styles.container}>
                <h4 className={styles.title}>Changing dates for: {customerFacingId}</h4>
                <form className={styles.form}>
                    <label className={styles.label} htmlFor="startDate">
                        Start date
                        <input
                            name="startDate"
                            id="startDate"
                            type="date"
                            onChange={(e) => {
                                const formatValue = format(new Date(e.target.value), 'yyyy-MM-dd');
                                setStartDate(formatValue);
                                setEndDate(
                                    format(
                                        sub(addYears(new Date(e.target.value), 1), { days: 1 }),
                                        'yyyy-MM-dd'
                                    )
                                );
                            }}
                            value={startDate}
                            className={styles.input}
                            data-testid="start-date-input"
                        />
                    </label>
                    <label className={styles.label} htmlFor="endDate">
                        End date
                        <input
                            name="endDate"
                            id="endDate"
                            type="date"
                            onChange={(e) =>
                                setEndDate(format(new Date(e.target.value), 'yyyy-MM-dd'))
                            }
                            value={endDate}
                            className={styles.input}
                            data-testid="end-date-input"
                        />
                    </label>
                </form>
                <ModalButtons
                    setIsOpen={setIsOpen}
                    handleSubmit={handleSubmit}
                    isSubmitting={isChangingDates}
                    isSubmitButtonDisabled={isButtonsDisabled}
                />
            </div>
        </Modal>
    );
};

export default ChangeDatesModal;
