import { CheckFilled, Important } from 'assets/pandoraIcons';
import { InfoBox } from 'components/InfoBox';
import { toCurrency } from 'utils/toCurrency';
import styles from '../index.module.scss';

export interface Props {
    total_cost_of_claims: number;
    annual_claim_cost_limit_gbp?: number | null;
}

export const TotalCost: React.FC<Props> = ({
    total_cost_of_claims,
    annual_claim_cost_limit_gbp,
}) => {
    const convertCostToPounds = Number(((total_cost_of_claims || 0) / 100).toFixed(2));

    const isWithinCostLimit =
        annual_claim_cost_limit_gbp && annual_claim_cost_limit_gbp - convertCostToPounds <= 50;

    const isGreater =
        annual_claim_cost_limit_gbp && convertCostToPounds > annual_claim_cost_limit_gbp;

    return (
        <InfoBox
            heading="Total Contract Cost (Ex VAT)"
            icon={
                isWithinCostLimit && annual_claim_cost_limit_gbp !== null ? (
                    <Important data-testid="warning-icon" />
                ) : (
                    <CheckFilled data-testid="check-icon" />
                )
            }
            customStyle={styles.totalCostContainer}
        >
            <p className={isGreater ? styles.totalCostWarning : ''} data-testid="cost-text">
                {toCurrency(total_cost_of_claims || 0)}
                {annual_claim_cost_limit_gbp && (
                    <span data-testid="annual-limit">{`/£${annual_claim_cost_limit_gbp}`}</span>
                )}
            </p>
        </InfoBox>
    );
};
