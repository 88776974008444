import { Skeleton } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
interface Props {
    repeat: number;
}

export const SkeletonLoader = ({ repeat }: Props) => {
    const skeletonLine = (key: number) => {
        return (
            <div key={key}>
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
            </div>
        );
    };

    const renderLoading = () => {
        return [...Array(repeat)].map((_value, index) => skeletonLine(index));
    };

    return <Container> {renderLoading()} </Container>;
};
