import { SecondaryButton } from 'components/buttons';

interface Props {
    toggleModal: () => void;
}

export const AuthoriseInvoiceButton = ({ toggleModal }: Props) => (
    <SecondaryButton onClick={() => toggleModal()} data-testid="authorise-button">
        Authorise Invoices
    </SecondaryButton>
);
