import { Box, Modal, SxProps, Typography, TextField } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { useState } from 'react';
import styled from 'styled-components';
import { newPricebook } from 'features/pricebook/api/pricebook/newPricebook';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { DatabasePricebooks } from 'features/pricebook/api/type';

interface Props {
    open: boolean;
    closeModal: () => void;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<DatabasePricebooks, unknown>>;
}

const boxStyle: SxProps = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
    left: '50%',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
};

const TextInputMui = styled.label`
    display: flex;
    align-items: center;
    color: #3f4955;
    font-weight: 600;
`;

const ButtonContainer = styled.div`
    display: 'flex';

    button {
        margin-right: 20px;
    }
`;

export const AddPricebookModal = ({ open, closeModal, refetch }: Props) => {
    const [pricebookName, setPricebookName] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const cleanForm = () => {
        setPricebookName('');
    };

    const onChangeFn = (
        e: React.ChangeEvent<HTMLInputElement>,
        setFn: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => {
        const { value } = e.target;
        setFn(value);
    };

    const onSubmit = async () => {
        if (!pricebookName) return;
        setIsLoading(true);
        await newPricebook({ name: pricebookName });
        refetch();
        cleanForm();
        closeModal();
        setIsLoading(false);
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <Box sx={boxStyle}>
                <Typography variant="h6" component="h2">
                    Add new pricebook
                </Typography>

                <TextInputMui htmlFor="pricebookName">
                    <TextField
                        id="pricebookName"
                        label="Name"
                        variant="outlined"
                        value={pricebookName || ''}
                        error={pricebookName === ''}
                        helperText={((): string => {
                            if (pricebookName === '' || pricebookName === undefined) {
                                return 'Name is required';
                            }

                            return '';
                        })()}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeFn(e, setPricebookName)
                        }
                        required
                    />
                </TextInputMui>

                <ButtonContainer>
                    <PrimaryButton
                        className="small"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={!pricebookName}
                    >
                        Add
                    </PrimaryButton>
                    <SecondaryButton className="small" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};
