import { Alert, AlertTitle } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { usePackagesQuery } from 'api/packages/usePackagesQuery';
import { LinkCell } from 'components/LinkCell';
import { SecondaryButton } from 'components/buttons';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { DatabasePricebooks } from 'features/pricebook/api/type';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Table } from '../Table';

const Container = styled.section`
    padding: 2rem 3rem;
`;

const columns = [
    {
        headerName: 'Call-out fee',
        field: 'contribution',
        width: 200,
        renderCell: (params: GridRenderCellParams<string>) => (
            <p>£ {params.row.contribution.toFixed(2)}</p>
        ),
    },
    {
        headerName: 'Price',
        field: 'price',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => (
            <p>£ {(params.row.price / 100).toFixed(2)}</p>
        ),
    },
    {
        headerName: 'Minimum renewal price',
        field: 'minimum_renewal_price',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => {
            if (params.row.minimum_renewal_price) {
                return <p>£ {(params.row.minimum_renewal_price / 100).toFixed(2)}</p>;
            }
            return <p>-</p>;
        },
    },
];

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    h2 {
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
    }
    p {
        margin: 0 1rem 0 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`;

const Details = styled.section`
    background-color: ${({ theme }) => theme.colors.gray_100};
    display: flex;
    padding: 2rem 3rem;
    margin: 2rem 0;
    justify-content: flex-start;
    gap: 2rem;
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: DatabasePricebooks | undefined;
}

export const PriceByCallOutFee = ({ data, isLoading, isError }: Props) => {
    const urlParams = useParams();
    const [redirectTo, setRedirectTo] = useState('');

    const {
        data: packagesData,
        isLoading: packagesIsLoading,
        isError: packagesIsError,
    } = usePackagesQuery();

    const packageName = packagesData?.data.find(
        (value) => value.id === Number(urlParams.packageId || 0)
    )?.name;

    if (isLoading || packagesIsLoading) {
        return (
            <Container>
                <SkeletonLoader repeat={3} />
            </Container>
        );
    }

    if (!data || !packagesData || isError || packagesIsError) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Pricebooks Entry Error</AlertTitle>
                    Unable to load pricebooks entry call-out fees
                </Alert>
            </Container>
        );
    }

    const pricebookName = data?.data[0].name || '';
    const pricebookEntriesData = data?.data[0].relations?.pricebook_entries
        ?.filter((entry) => {
            return entry.package_id === Number(urlParams.packageId || 0);
        })
        .filter((entry) => {
            return (
                entry.active === 1 &&
                (entry.contribution || entry.contribution === 0) &&
                (entry.price || entry.price === 0)
            );
        });

    if (redirectTo) {
        return <Navigate to={redirectTo} />;
    }

    return (
        <Container>
            <Header>
                <h2>Call-out Fee</h2>
                <ButtonContainer>
                    <SecondaryButton
                        onClick={() => {
                            setRedirectTo(`edit`);
                        }}
                    >
                        Edit
                    </SecondaryButton>
                </ButtonContainer>
            </Header>
            <Details>
                <LinkCell header="Pricebook" body={pricebookName} />
                <LinkCell header="Package" body={packageName || ''} />
            </Details>
            <Table columns={columns} rows={[...(pricebookEntriesData || [])]} hideFooter />
        </Container>
    );
};
