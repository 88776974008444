import { Alert, AlertTitle, TextField, Button, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { DatabasePricebooks } from 'features/pricebook/api/type';
import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { useControllerQuery } from 'features/pricebook/hooks/useControllerQuery';
import { useToggle } from 'hooks/useToggle';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { Table } from '../Table';
import { AddButton } from '../NewPricebookForm/AddButton';
import { AddPricebookModal } from '../AddNewPricebookModal';

const Container = styled.section`
    padding: 2rem 3rem;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    h2 {
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
    }
    p {
        margin: 0 1rem 0 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`;

const GreenSquare = styled.span`
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #d4ffba;
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: DatabasePricebooks | undefined;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<DatabasePricebooks, unknown>>;
}

export const PricebookList = ({ data, isLoading, isError, refetch }: Props) => {
    const [redirectTo, setRedirectTo] = useState('');
    const [isAddPricebookModalOpen, toggleAddPricebookModalOpen] = useToggle(false);

    const { data: controllerData } = useControllerQuery();
    const livePricebookId = String(controllerData?.data[0].default_pricebook_id);

    if (isLoading) {
        return (
            <Container>
                <SkeletonLoader repeat={3} />
            </Container>
        );
    }

    if (!data || isError) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Pricebooks Details Error</AlertTitle>
                    Unable to load pricebooks details
                </Alert>
            </Container>
        );
    }

    const pricebooksData = data?.data.map((pricebook) => {
        const mapActiveFlag = (flag: boolean) => (flag ? 'Yes' : 'No');
        const formatModifiedDate = format(new Date(pricebook.date_modified), 'dd-MM-yyyy HH:mm');
        return {
            key: pricebook.id,
            isActive: mapActiveFlag(pricebook.active),
            formatModifiedDate,
            ...pricebook,
        };
    });

    const columns = [
        {
            headerName: 'Active',
            field: 'isActive',
            width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    <TextField
                        id="pricebookIsActive"
                        select
                        value={params.row.isActive}
                        // eslint-disable-next-line no-console
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => console.log(e)}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true, // <== added this
                        }}
                        disabled
                    >
                        <MenuItem key="yes" value="Yes">
                            Yes
                        </MenuItem>
                        <MenuItem key="no" value="No">
                            No
                        </MenuItem>
                    </TextField>
                </>
            ),
        },
        {
            headerName: 'Name',
            field: 'name',
            flex: 1,
        },
        {
            headerName: 'ID',
            field: 'id',
            width: 100,
        },
        {
            headerName: 'Modified Date',
            field: 'formatModifiedDate',
            flex: 1,
        },
        {
            headerName: '',
            field: 'edit',
            width: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button
                    type="button"
                    onClick={() => {
                        setRedirectTo(`/pricebooks/${params.row.id}`);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    if (redirectTo) {
        return <Navigate to={redirectTo} />;
    }

    return (
        <>
            <AddPricebookModal
                open={isAddPricebookModalOpen}
                closeModal={() => {
                    toggleAddPricebookModalOpen();
                }}
                refetch={refetch}
            />
            <Container>
                <Header>
                    <h2>Pricebook List</h2>
                    <ButtonContainer>
                        <AddButton toggleModal={toggleAddPricebookModalOpen} />
                    </ButtonContainer>
                </Header>
                <p>
                    <GreenSquare /> = Current pricebook
                </p>
                <Table
                    columns={columns}
                    rows={[...pricebooksData]}
                    highlightKey={livePricebookId}
                    hideFooter
                />
            </Container>
        </>
    );
};
