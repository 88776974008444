import { Alert, AlertTitle } from '@mui/material';
import { Arrow } from 'assets/pandoraIcons';
import { LinkCell } from 'components/LinkCell';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { DatabaseClaim } from 'features/claim/api/claim/type';
import { useJobsQuery } from 'features/claim/hooks/useJobsQuery';
import { useState } from 'react';
import styled from 'styled-components';
import { toCurrency } from 'utils/toCurrency';
import { Cell } from './Cell';
import { CostBreakdown } from './CostBreakdown';

const Container = styled.section`
    background-color: ${({ theme }) => theme.colors.gray_100};
    display: grid;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 7em);
    padding: 2rem 3rem;
    height: 15em;
`;
const SkeletonContainer = styled.section`
    padding: 2rem 3rem;
`;

const CostSpan = styled.span`
    cursor: pointer;
    svg {
        vertical-align: middle;
    }
`;

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: DatabaseClaim | undefined;
}

export const ClaimDetails = ({ data, isLoading, isError }: Props) => {
    const [showOtherCost, setShowOtherCost] = useState(false);
    // const { data, isLoading, isError } = useClaimQuery()
    const { data: jobsData, isLoading: jobsIsLoading, isError: jobsIsError } = useJobsQuery();

    if (isLoading && jobsIsLoading) {
        return (
            <SkeletonContainer>
                <SkeletonLoader repeat={2} />
            </SkeletonContainer>
        );
    }

    if (isError || jobsIsError || !data || !jobsData) {
        return (
            <SkeletonContainer>
                <Alert severity="error">
                    <AlertTitle>Claim Details Error</AlertTitle>
                    Unable to load claim information for id
                </Alert>
            </SkeletonContainer>
        );
    }

    return (
        <Container>
            <Cell header="Claim Id" body={data.id.toString()} />
            <Cell header="Claim Type" body={data.type || 'Unknown'} />
            <Cell header="Claim Status" body={data.claim_status_name || 'Unknown'} />
            <LinkCell
                header="Claim Contract"
                body={`${data?.contract_id}`}
                link={`/contract/${data.contract_id}`}
                openInNewTab={false}
            />
            <Cell
                header="Start Date"
                body={new Date(data.date_created).toLocaleDateString('en-UK')}
            />
            <Cell
                header="Number of Completed Jobs"
                body={jobsData.jobs
                    .reduce(
                        (accumulator, currentValue) =>
                            currentValue.sk_job_status === 'Complete'
                                ? accumulator + 1
                                : accumulator,
                        0
                    )
                    .toString()}
            />
            {showOtherCost ? (
                <CostBreakdown claim={data} setShowOtherCost={setShowOtherCost} />
            ) : (
                <Cell
                    header={
                        <>
                            <CostSpan
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => setShowOtherCost(true)}
                                onClick={() => setShowOtherCost(true)}
                            >
                                Total Cost to Date (ex VAT)
                                <Arrow />
                            </CostSpan>
                        </>
                    }
                    body={toCurrency(data.charge_total_cost || 0)}
                />
            )}
            <Cell
                header="Limit"
                body={data.claim_cost_limit ? `£${data.claim_cost_limit}` : '--'}
            />
            <Cell header="Urgency" body={data.sk_urgency || 'Normal'} />
            <Cell header="Vulnerable Customer" body={data.sk_vulnerable_customer || 'No'} />
        </Container>
    );
};
