import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import axios from 'axios';
import { CONTRACTS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type UpdateContractInput = {
    id: number;
    payment_amount?: number;
    contribution?: number;
    status?: number;
    triggerConfirmationEmail?: boolean;
    cancellation_type?: string | null;
    cancellation_description?: string | null;
    can_raise_claim?: boolean;
    date_created?: string;
    promo_code_id?: number | null;
    automatically_renew?: boolean | null;
};

export type UpdateContractOutput = { success: boolean };

export const updateContract = async (payload: UpdateContractInput) => {
    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios.post(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/contracts/update`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    if (response.data.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    if (response.data.status === 'error') {
        throw new Error('Could not update contract');
    }
    return response.data;
};
