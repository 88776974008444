import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface CancelContractRequestPayload {
    cancellation_status: number;
    cancellation_type: number;
    cancellation_description: string;
    id: number;
    cancel_gocardless: boolean;
    cancel_chargebee: boolean;
}
export interface CancellationOptions {
    id: number;
    active: number;
    author?: string | null;
    name: string;
}

export interface CancellationOptionsResponse {
    data: CancellationOptions[];
}

export const CancellationOptionschema: JSONSchemaType<CancellationOptionsResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    active: { type: 'number' },
                    author: { type: 'string', nullable: true },
                    name: { type: 'string' },
                },
                required: ['id', 'name'],
            },
        },
    },
    required: ['data'],
};

export type GetCancellationOptionsResponse = PandoraErrorResponse | CancellationOptionsResponse;

export const getCancellationOptionsResponseSchema: JSONSchemaType<GetCancellationOptionsResponse> =
    {
        type: 'object',
        anyOf: [CancellationOptionschema, pandoraErrorResponseSchema],
        additionalProperties: true,
    };
