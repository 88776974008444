import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getJobsByClaimId } from '../api/jobs';

export const useJobsQuery = () => {
    const urlParams = useParams();

    if (!urlParams.claimId) {
        throw new Error('claimId is required');
    }

    const { claimId } = urlParams;

    return useQuery(['jobs', { claimId }], getJobsByClaimId, {
        enabled: !!claimId,
    });
};
