import { Contract } from 'api/contract/types';
import { format } from 'date-fns';
import styles from '../index.module.scss';

const ContractDetailsFooter = ({ data }: { data: Contract }) => {
    return (
        <div className={styles.accordionFooter}>
            <p>Author: {data.author}</p>
            <p>Created Date: {format(new Date(data.date_created), 'dd-MM-yyyy | HH:mm:ss')}</p>
            <p>Modified By: {data.last_modified_by}</p>
            <p>Modified Date: {format(new Date(data.date_modified), 'dd-MM-yyyy | HH:mm:ss')}</p>
        </div>
    );
};

export default ContractDetailsFooter;
