import { Contract } from 'api/contract/types';
import { PackageType } from 'types/enum/PackageType';
import { ContractTypes } from '../api/types';

export const getContractTypeName = (
    typesData: ContractTypes[] | undefined,
    contractData: Contract
) => {
    if (contractData.type) {
        return typesData?.find((type) => type.id === contractData.type)?.name;
    }

    if (contractData.package?.type_id) {
        return PackageType[contractData.package.type_id];
    }
    return undefined;
};
