import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

// MUI
import Box from '@mui/material/Box';
import { Modal, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

// Components
import { useClaimActivityMutation } from 'features/claim/hooks/useClaimActivityMutation';
import { PrimaryButton, SecondaryButton } from 'components/buttons';

interface Props {
    open: boolean;
    closeModal: () => void;
}

const boxStyle: SxProps = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
    left: '50%',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
};

// const inputStyle: SxProps = {
//     marginTop: '20px',
// }

const ButtonContainer = styled.div`
    display: 'flex';

    button {
        margin-right: 20px;
    }
`;

const SummaryInput = styled.label`
    display: flex;
    align-items: center;
    color: #3f4955;
    font-weight: 600;

    input {
        margin-left: 2em;
        padding: 0 1em;
        width: 100%;
        font-size: 1em;
        line-height: 2em;
        border: 1px solid #d8dbdd;
        box-sizing: border-box;
        border-radius: 20px;
    }
`;

const DescriptionInput = styled.label`
    display: flex;
    flex-direction: column;
    color: #3f4955;

    textarea {
        border: 1px solid #d8dbdd;
        min-height: 5em;
        resize: none;
    }
`;

export const CreateActivityModal = ({ open, closeModal }: Props) => {
    const urlParams = useParams();
    const { mutate } = useClaimActivityMutation();
    const [summary, setSummary] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const cleanForm = () => {
        setSummary('');
        setDescription('');
    };

    const onSubmit = async () => {
        setIsLoading(true);
        mutate(
            {
                claimId: parseInt(urlParams.claimId!, 10),
                summary,
                description,
            },
            {
                onSuccess: () => {
                    cleanForm();
                    closeModal();
                },
                onSettled: () => setIsLoading(false),
            }
        );
    };

    return (
        <Modal data-testid="new-claim-activity-modal" open={open} onClose={closeModal}>
            <Box sx={boxStyle}>
                <Typography data-testid="new-claim-activity" variant="h6" component="h2">
                    Add note
                </Typography>

                <SummaryInput htmlFor="summary">
                    Summary
                    <input
                        id="summary"
                        type="text"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                    />
                </SummaryInput>

                <DescriptionInput htmlFor="details">
                    Additional information
                    <textarea
                        id="details"
                        name="details"
                        value={description}
                        onChange={(e) => setDescription(e.target.value as string)}
                    />
                </DescriptionInput>

                <ButtonContainer>
                    <PrimaryButton
                        className="small"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={summary === '' || description === ''}
                    >
                        Add
                    </PrimaryButton>
                    <SecondaryButton className="small" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </ButtonContainer>
            </Box>
        </Modal>
    );
};
