import { OpenArrow } from 'assets/pandoraIcons';
import { useState } from 'react';
import styles from './index.module.scss';

interface Props {
    accordionTitles: string[];
    displayOptions: Map<string, JSX.Element>;
    defaultOpen: number | null;
}

const Accordion = ({ accordionTitles, displayOptions, defaultOpen }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(defaultOpen);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const toDisplay = accordionTitles[activeIndex as number];
    const activeElement = toDisplay ? displayOptions.get(toDisplay) : '';

    return (
        <div className={styles.accordionContainer}>
            {accordionTitles.map((item, index) => (
                <div key={item} className={styles.accordionItem}>
                    <button
                        className={styles.accordionTitle}
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={activeIndex === index}
                        type="button"
                    >
                        {item}
                        <span>
                            {activeIndex === index ? (
                                <OpenArrow className={styles.rotated} />
                            ) : (
                                <OpenArrow />
                            )}
                        </span>
                    </button>
                    <div
                        className={`${styles.accordionContent} ${
                            activeIndex === index ? `${styles.open}` : ''
                        }`}
                    >
                        {activeIndex || activeIndex === 0 ? activeElement : ''}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
