import { DataGrid, GridColumns, GridInitialState } from '@mui/x-data-grid';

interface Props {
    rows: Record<string, any>[];
    columns: GridColumns;
    hideFooter: boolean;
    defaultSort?: { column: string; direction: 'asc' | 'desc' };
}

export const Table = ({ rows, columns, hideFooter, defaultSort }: Props) => {
    let initialState: GridInitialState = {};
    if (defaultSort) {
        initialState = {
            sorting: {
                sortModel: [{ field: defaultSort.column, sort: defaultSort.direction }],
            },
        };
    }

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            columnBuffer={columns.length} // columnBuffer prop required for tests to ensure all columns get rendered
            hideFooter={hideFooter}
            initialState={initialState}
            autoHeight
        />
    );
};
