import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { MutationFunction } from 'react-query';
import { CLAIMS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { validateClaimResponse } from '.';
import { DatabaseClaim, PatchClaimBody } from './type';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

type PatchJobClaimQueryKey = { claimId: string; body: PatchClaimBody };

export const patchClaimById: MutationFunction<DatabaseClaim, PatchJobClaimQueryKey> = async (
    queryKey
) => {
    const { claimId, body } = queryKey;
    const token = await getAmplifyIdTokenFromCookie();

    const response = await fetch(`${API_BASE_URL}/${CLAIMS_BASE_PATH}/v1/claim/${claimId}`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    const data = await validateClaimResponse(response);

    return data;
};
