import { Table } from '@HometreeEngineering/component-library';
import { Claim } from 'api/contract/types';
import { CardboardIcon } from 'assets/pandoraIcons';
import { buildPMSClaimsTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import styles from '../../index.module.scss';

const PMSClaimsTable = ({ pmsClaims }: { pmsClaims: Claim[] }) => {
    const headers = useMemo(
        () => [
            { key: 'pms_id', value: 'Claim ID' },
            { key: 'trade', value: 'PMS trade' },
            { key: 'job_type', value: 'Job type' },
            { key: 'claim_start_date', value: 'Claim start date' },
            { key: 'created_date', value: 'Created date' },
            { key: 'charge_final', value: 'Cost to Hometree' },
            { key: 'summary', value: 'Summary' },
        ],
        []
    );

    const data = useMemo(() => buildPMSClaimsTableData(pmsClaims), [pmsClaims]);

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                data={data}
                customStyle={styles.differentHeader}
                title="PMS Claims / ASV"
                icon={<CardboardIcon />}
                headerVariant="orange"
            />
        </div>
    );
};

export default PMSClaimsTable;
