import { getMandatoryEnvVar } from 'utils/env';
import styles from './index.module.scss';

interface Props {
    customerFacingId: string;
}

const gcURL = getMandatoryEnvVar('REACT_APP_GC_URL');

const ModalHeader = ({ customerFacingId }: Props) => (
    <div className={styles.titleWrapper}>
        <h4 className={styles.title}>Cancelling: {customerFacingId}</h4>
        <div className={styles.linksWrapper}>
            <a
                target="_blank"
                rel="noreferrer"
                href={`${gcURL}/mandates/`}
                className={styles.links}
            >
                Find Mandate
            </a>
            <a
                target="_blank"
                rel="noreferrer"
                href={`${gcURL}/subscriptions/`}
                className={styles.links}
            >
                Find Subscription
            </a>
            <a
                target="_blank"
                rel="noreferrer"
                href={`${gcURL}/customers/`}
                className={styles.links}
            >
                Find Customer
            </a>
        </div>
    </div>
);

export default ModalHeader;
