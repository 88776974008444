import { GetContractByIdQueryKey, Params, getContractById } from 'api/contract';
import { ContractResponse } from 'api/contract/types';
import { useQuery } from 'react-query';

export const useContractQuery = ({
    contractId,
    params,
}: {
    contractId?: number;
    params?: Params;
}) => {
    const query = ['contract'] as unknown as GetContractByIdQueryKey;
    let enabled = {};
    if (contractId) {
        query.push(contractId);
        enabled = {
            enabled: !!contractId,
        };
    }
    if (params) {
        query.push(params);
    }

    return useQuery<ContractResponse, Error, ContractResponse, typeof query>(
        query,
        getContractById,
        enabled
    );
};
