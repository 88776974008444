import { Contract } from 'api/contract/types';
import { useCancellationTypesQuery } from 'api/contract/useCancellationOptionsQuery';
import { ChevronDown, ChevronUp, HistoryIcon } from 'assets/pandoraIcons';
import { InfoBox } from 'components/InfoBox';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { mapContractStatus } from 'utils/mapContractStatus';
import ContractHistory from '../ContractHistory';
import styles from './index.module.scss';

export interface ContractStatusInfoProps {
    details: Contract;
}

export const ContractStatusInfo = ({ details }: ContractStatusInfoProps) => {
    const [isStatusHistoryOpen, setIsStatusHistoryOpen] = useState(false);
    const [isCancellationInfoOpen, setIsCancellationInfoOpen] = useState(false);

    const toggleCancellationInfo = () => {
        setIsCancellationInfoOpen(!isCancellationInfoOpen);
        if (isStatusHistoryOpen) {
            setIsStatusHistoryOpen(false);
        }
    };

    const toggleStatusHistory = () => {
        setIsStatusHistoryOpen(!isStatusHistoryOpen);
        if (isCancellationInfoOpen) {
            setIsCancellationInfoOpen(false);
        }
    };

    const contractStatusText = mapContractStatus(details.status);

    const {
        data: cancellationTypesData,
        error: cancellationTypesError,
        isError,
    } = useCancellationTypesQuery();

    const cancellationTypes = cancellationTypesData?.data || [];

    useEffect(() => {
        if (cancellationTypesError && isError) {
            toast.error(
                (cancellationTypesError as Error)?.message || 'Error fetching cancellation types'
            );
        }
    }, [isError, cancellationTypesError]);

    const getCancellationType = () => {
        const cancellationType = cancellationTypes?.find(
            (type) => type.id === details.cancellation_type
        )?.name;
        return cancellationType ? <p>{cancellationType}</p> : <p>-</p>;
    };

    const isCancelled = contractStatusText === 'Cancelled';

    return (
        <div className={`${styles.infoBoxWrapper} `}>
            <InfoBox
                heading="Contract Status:"
                icon={
                    <HistoryIcon
                        data-testid="history-icon"
                        className={`${styles.historyIcon} ${
                            isStatusHistoryOpen ? styles.open : ''
                        } ${isCancelled ? styles.cancelledIcon : ''}`}
                        onClick={toggleStatusHistory}
                    />
                }
                isOneLine
                background={isCancelled ? 'red' : 'white'}
            >
                <>
                    <p>{mapContractStatus(details.status)}</p>
                    {isCancelled && (
                        <button
                            className={styles.cancellationDropdownButton}
                            type="button"
                            onClick={toggleCancellationInfo}
                        >
                            {isCancellationInfoOpen ? (
                                <span className={styles.icon}>
                                    <ChevronUp />
                                </span>
                            ) : (
                                <span className={styles.icon}>
                                    <ChevronDown />
                                </span>
                            )}
                        </button>
                    )}
                </>
            </InfoBox>
            {isCancelled && isCancellationInfoOpen ? (
                <div className={styles.cancellationDetails}>
                    <h5>Cancelation Reason:</h5>
                    {getCancellationType()}
                    <div className={styles.divider}>
                        <h5>Details:</h5>
                        <p>{details.cancellation_description || ''}</p>
                    </div>
                </div>
            ) : null}

            <ContractHistory
                data={details}
                isOpen={isStatusHistoryOpen}
                historyKey="status"
                alignment={styles.historyAlignment}
            />
        </div>
    );
};

export default ContractStatusInfo;
