import { SecondaryButton } from 'components/buttons';

interface Props {
    saveFn: () => void;
    disabled: boolean;
}

export const SaveButton = ({ saveFn, disabled }: Props) => {
    return (
        <SecondaryButton onClick={() => saveFn()} disabled={disabled}>
            Save and return
        </SecondaryButton>
    );
};
